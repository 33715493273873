<mat-form-field>
  <input matInput type="text" [required]="isRequired"
         placeholder="{{placeholder}}"
         [formControl]="inputFormControl"
         [readonly]="isReadOnly"
         *ngIf="!isTextField">
  <textarea matInput [required]="isRequired"
            cdkTextareaAutosize
            placeholder="{{placeholder}}"
            [formControl]="inputFormControl"
            *ngIf="isTextField"
            maxlength="2500"></textarea>
</mat-form-field>
