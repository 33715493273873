<div>
  <mat-toolbar>
    <button mat-icon-button class="burger-icon" (click)="triggerNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="logo">
      <img src="./assets/pq-logo.png" alt="ProQrent GmbH Logo">
    </div>
    <span class="navbar-spacer"></span>
    <a routerLink="/home/profil" routerLinkActive="active" *ngIf="!adminOnly" (click)="toggleAdminView(false)">Profil</a>
    <a routerLink="/home/admin" routerLinkActive="active" *ngIf="isAdmin || adminOnly" (click)="toggleAdminView(true)">Admin</a>
    <a routerLink="/employee"  routerLinkActive="active">User</a>
    <button class="logout" mat-icon-button (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-toolbar>
</div>
