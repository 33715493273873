<mat-dialog-content>
  <div class="main">
    <h1>Version Vergleich</h1>
    <div>
      <mat-form-field class="first-Version" appearance="fill">
        <mat-label>Erste Version</mat-label>
        <mat-select [(value)]="previousVersion" (valueChange)="getVersions()">
          <mat-option *ngFor="let version of versions" [value]="version.version"> Version {{version.version +1}} vom {{version.createdAt | date: 'dd.MM.yyyy'}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="second-Version" appearance="fill">
        <mat-label>Zweite Version</mat-label>
        <mat-select [(value)]="afterVersion" (valueChange)="getVersions()">
          <mat-option *ngFor="let version of versions" [value]="version.version"> Version {{version.version +1}} vom {{version.createdAt | date: 'dd.MM.yyyy'}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="versionsSet;else oneVersion">
      <mat-expansion-panel disabled class="toggle-panel" hideToggle="true" expanded *ngIf="comparisonData">
        <mat-expansion-panel-header>
          <span class="mat-expansion-panel-header-text">
            Vergleich von Version {{previousVersion+1}} und Version {{afterVersion+1}}
          </span>
          <span class="spacer"></span>
          <button class="toggle-button" mat-icon-button (click)="toggleAll($event)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{toggleMode ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
        </mat-expansion-panel-header>
        <div *ngIf="comparisonData">
          <app-property-change #propertyToggle [propertyChangeList]="comparisonData.profileChanges"></app-property-change>
        </div>
          <div *ngFor="let project of comparisonData.projectsChanges">
            <mat-button-toggle #projectToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{projectToggle.checked ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </mat-button-toggle>
            <h3>Projekt {{project.projectId + 1}}</h3>
            <div *ngIf="projectToggle.checked" class="project-container">
              <app-property-change #projectsToggle [propertyChangeList]="project.projectChanges"></app-property-change>
            </div>
          </div>
      </mat-expansion-panel>
    </div>
    <ng-template #oneVersion>Sie haben nur eine Version ausgewählt</ng-template>
  </div>
</mat-dialog-content>
