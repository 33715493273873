export enum PropertiesTranslations {
    firstname = 'Vorname',
    lastname = 'Nachname',
    birthdate = 'Geburtstag',
    education = 'Abschluss',
    knowHowLevel = 'Know-How Stufe',
    language = 'Muttersprache',
    foreignLanguage = 'Fremdsprachen',
    experience = 'Berufserfahrung',
    certifications = 'Weiterbildung',
    branches = 'Branchenerfahrung',
    methodicKnowledge = 'Methodisches Wissen',
    programmingLanguages = 'Programmiersprachen',
    frameworks = 'Technologien / Frameworks',
    tools = 'Tools / Entwicklungsumgebungen',
    databases = 'Datenbanken/ Datenbanktools',
    operatingSystems = 'Betriebssysteme',
    basicKnowledge = 'Grundkenntnisse',

    projectName = 'Projekt Name',
    client = 'Klient',
    role = 'Rolle im Projekt',
    keySkills = 'Schlüsselkenntnisse',
    teamSize = 'Teamgröße',
    projectDescription = 'Kurzbeschreibung',
    todos = 'Aufgaben',
    projectLength = 'Projektdauer'
}
