import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { KeycloakDataService } from '../service/keycloak-data.service';

@Injectable({
    providedIn: 'root'
})
export class OnlyLoggedInUsersGuard implements CanActivate {
    constructor(private router: Router, private keycloak: KeycloakDataService) {}

    canActivate(): boolean {
        if (this.keycloak.isLoggedIn()) {
            return true;
        } else {
            this.keycloak.logout();

            return false;
        }
    }
}
