import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-personal-profile-data-edit',
    templateUrl: './personal-profile-data-edit.component.html',
    styleUrls: ['./personal-profile-data-edit.component.scss']
})
export class PersonalProfileDataEditComponent implements OnInit {
    @Input() personalProfileData: FormGroup;

    ngOnInit(): void {
        this.personalProfileData.markAllAsTouched();
    }
}
