import { Component, Input, OnInit } from '@angular/core';

import { PropertyComparison, StringChange } from '../../../../../../models/ComparisonModels';

@Component({
    selector: 'app-compare-list',
    templateUrl: './compare-list.component.html',
    styleUrls: ['./compare-list.component.scss']
})
export class CompareListComponent implements OnInit {
    @Input() listComparison: PropertyComparison;
    displayedColumns: string[] = ['previous', 'after'];
    datasource: StringChange[];

    ngOnInit(): void {
        this.datasource = this.listComparison.listChange;
    }
}
