import { Component, Input, OnInit } from '@angular/core';

import { LanguageTranslation } from '../../../../../../enum/LanguageTranslation';
import { MapChange, PropertyComparison } from '../../../../../../models/ComparisonModels';

@Component({
    selector: 'app-compare-map',
    templateUrl: './compare-map.component.html',
    styleUrls: ['./compare-map.component.scss']
})
export class CompareMapComponent implements OnInit {
    @Input() mapComparison: PropertyComparison;
    translations: typeof LanguageTranslation = LanguageTranslation;
    displayedColumns: string[] = ['key', 'previous', 'after'];
    datasource: MapChange;

    ngOnInit(): void {
        this.datasource = this.mapComparison.mapChange;
    }
}
