import { Component, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonToggle } from '@angular/material/button-toggle';

import { VersionInfo } from '../../../../models/VersionInfo';
import { ComparisonRequest, ComparisonResponse } from '../../../../models/ComparisonModels';
import { UserDataService } from '../../../../service/user-data.service';

import { PropertyChangeComponent } from './property-change/property-change.component';

@Component({
    selector: 'app-compare-dialog',
    templateUrl: './compare-dialog.component.html',
    styleUrls: ['./compare-dialog.component.scss']
})
export class CompareDialogComponent {
    toggleMode = false;
    previousVersion: number;
    afterVersion: number = this.versions[this.versions.length - 1].version;
    comparisonData: ComparisonResponse;
    @ViewChildren('projectToggle') projectToggle: QueryList<MatButtonToggle>;
    @ViewChildren('projectsToggle') projectsToggle: QueryList<PropertyChangeComponent>;
    @ViewChild('propertyToggle') propertyToggle;

    constructor(@Inject(MAT_DIALOG_DATA) public versions: VersionInfo[], private userDataService: UserDataService) {}

    versionsSet = (): boolean =>
        // eslint-disable-next-line
        this.previousVersion !== undefined && this.afterVersion !== undefined && this.previousVersion !== this.afterVersion

    getVersions(): void {
        if (this.versionsSet) {
            if (this.userDataService.adminView) {
                this.userDataService
                    .getVersionComparisonAdmin(
                        new ComparisonRequest(this.previousVersion, this.afterVersion),
                        this.userDataService.keyCloakName
                    )
                    .subscribe((comparison) => {
                        this.comparisonData = comparison;
                        this.comparisonData.projectsChanges.sort((a, b) => a.projectId - b.projectId);
                    });
            } else {
                this.userDataService
                    .getVersionComparison(new ComparisonRequest(this.previousVersion, this.afterVersion))
                    .subscribe((comparison) => {
                        this.comparisonData = comparison;
                        this.comparisonData.projectsChanges.sort((a, b) => a.projectId - b.projectId);
                    });
            }
        }

        this.toggleMode = false;
    }

    toggleAll($event: MouseEvent): void {
        $event.stopPropagation();
        if (this.versionsSet() && this.propertyToggle.propertyToggleList) {
            this.propertyToggle.propertyToggleList.forEach((toggle) => {
                toggle.checked = !this.toggleMode;
            });
            this.projectToggle.forEach((toggle) => {
                toggle.checked = !this.toggleMode;
            });
            setTimeout(() => {
                this.projectsToggle.forEach((project) => {
                    if (this.projectsToggle) {
                        project.propertyToggleList.forEach((toggle) => {
                            toggle.checked = !this.toggleMode;
                        });
                    }
                });
                this.toggleMode = !this.toggleMode;
            }, 100);
        }
    }
}
