<div *ngIf="knowledgeForm">
  <dt><h1 class="section-title">Fachwissen / Kenntnisse / Schwerpunkte</h1></dt>
  <h3>Progammiersprachen</h3>
  <app-chip-list [formController]="knowledgeForm.get('programmingLanguages')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'programming-languages'">
  </app-chip-list>
  <h3>Technologien / Frameworks</h3>
  <app-chip-list [formController]="knowledgeForm.get('frameworks')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'frameworks'">
  </app-chip-list>
  <h3>Tools / Entwicklungsumgebungen</h3>
  <app-chip-list [formController]="knowledgeForm.get('tools')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'tools'">
  </app-chip-list>
  <h3>Datenbanken / Datenbanktools</h3>
  <app-chip-list [formController]="knowledgeForm.get('databases')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'databases'">
  </app-chip-list>
  <h3>Betriebssyteme</h3>
  <app-chip-list [formController]="knowledgeForm.get('operatingSystems')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'operating-systems'">
  </app-chip-list>
  <h3>Grundkentnisse</h3>
  <app-chip-list [formController]="knowledgeForm.get('basicKnowledge')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'basic-knowledge'">
  </app-chip-list>
  <h3>Methodisches Wissen</h3>
  <app-chip-list [formController]="knowledgeForm.get('methodicKnowledge')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'methodic-knowledge'">
  </app-chip-list>
  <h3>Branchenerfahrung</h3>
  <app-chip-list [formController]="knowledgeForm.get('branches')"
                 [isArray]="true" [hasAutocomplete]="true" [autoCompleteName]="'branches'">
  </app-chip-list>
</div>
