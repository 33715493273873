import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Injectable()
export class KeycloakDataService {
    private username: string;

    private profile: KeycloakProfile;

    constructor(private keycloak: KeycloakService) {
        this.getProfile().then((profile) => {
            this.profile = profile;
        });
        this.getUserName().then((username) => {
            this.username = username;
        });
        this.getToken();
    }

    async getUserName(): Promise<string> {
        if (this.username !== undefined && this.username !== null) {
            return this.username;
        } else {
            this.username = this.keycloak.getUsername();

            return this.username;
        }
    }

    getToken(): Promise<string> {
        return this.keycloak.getToken();
    }

    async getProfile(): Promise<KeycloakProfile> {
        if (this.profile !== undefined) {
            return this.profile;
        } else {
            await this.keycloak.loadUserProfile().then((keycloakprofile) => {
                this.profile = keycloakprofile;
            });

            return this.profile;
        }
    }

    // tslint:disable-next-line:typedef
    checkAdmin(): boolean {
        return this.keycloak.getUserRoles().includes('admin', 0);
    }

    // tslint:disable-next-line:typedef
    logout(): void {
        this.keycloak.logout();
    }

    getRole(): string[] {
        return this.keycloak.getUserRoles();
    }

    isLoggedIn(): Promise<boolean> {
        return this.keycloak.isLoggedIn();
    }
}
