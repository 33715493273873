import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChip, MatChipInputEvent, MatChipList } from '@angular/material/chips';

// eslint-disable-next-line max-len
import { ChangeForeignLanguageDialogComponent } from '../../personal-data/personal-data-edit/change-foreign-language-dialog/change-foreign-language-dialog.component';
import { UserDataService } from '../../../../service/user-data.service';

@Component({
    selector: 'app-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit {
    @Input() formController: FormControl;
    @Input() isMap: boolean;
    @Input() isArray: boolean;
    @Input() hasAutocomplete: boolean;
    @Input() autoCompleteName: string;
    @ViewChild('inputElement') inputElement: ElementRef;
    @ViewChild('foreignLanguage') foreignlanguageInput: MatInput;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('chipList') chipList: MatChipList;

    autoCompleteArray: string[];
    filteredAutoCompleteArray: Observable<string[]>;
    separatorKeysCodes: number[] = [ENTER];
    currentPosition: number;
    chipInput = new FormControl();
    chipListControl = new FormControl([]);
    isLanguageDialogOpen: boolean;

    constructor(private dialog: MatDialog, private userDataService: UserDataService) {}

    ngOnInit(): void {
        if (this.hasAutocomplete) {
            this.getAutoCompleteArray(this.autoCompleteName);
        }
        if (this.isRequired) {
            this.chipListControl.setValidators(Validators.required);
        }
        this.chipListControl.setValue(Object.assign([], this.formController.value));
        this.chipListControl.markAllAsTouched();
    }

    unsorted(): number {
        return 0;
    }

    addChip(event: any): void {
        const isSelected = this.matAutocomplete.options.some((x) => x.active === true);

        this.chipListControl.setValue(Object.assign([], this.formController.value));
        if (event.value.trim() !== '' && !isSelected) {
            this.formController.value.push(event.value.trim());
            this.chipListControl.value.push(event.value.trim());
            this.formController.updateValueAndValidity();
            this.chipListControl.updateValueAndValidity();
            this.chipInput.setValue('');
            this.inputElement.nativeElement.value = '';
        }
    }

    get isRequired(): boolean {
        const validator = this.formController.validator ? this.formController.validator({} as AbstractControl) : '';

        if (validator && validator.required) {
            return true;
        }
    }

    openChangeLanguageDialog(language: any, level: any): void {
        if (language !== '' && language !== null && !this.isLanguageDialogOpen) {
            this.isLanguageDialogOpen = true;
            switch (level) {
                case 'Grundkenntnisse':
                    level = 1;
                    break;
                case 'gute Kenntnisse':
                    level = 2;
                    break;
                case 'fließend':
                    level = 3;
                    break;
                case 'verhandlungssicher':
                    level = 4;
                    break;
                default:
                    level = 1;
                    break;
            }
            const dialogRef = this.dialog.open(ChangeForeignLanguageDialogComponent, { data: { language, level } });

            dialogRef.afterClosed().subscribe((value) => {
                if (value) {
                    this.formController.value.set(language, value);
                }
                this.chipInput.setValue('');
                this.isLanguageDialogOpen = false;
            });
        }
    }

    toggleSelection(value: string, form: FormControl): void {
        if (!form.value.includes(value)) {
            form.value.push(value);
            this.chipListControl.value.push(value);
        } else {
            form.value.splice(form.value.indexOf(value), 1);
            this.chipListControl.value.splice(form.value.indexOf(value), 1);
        }
        form.updateValueAndValidity();
        this.chipListControl.updateValueAndValidity();
        this.chipInput.setValue('');
        this.inputElement.nativeElement.value = '';
    }

    editChip(arrayItem): void {
        const index = this.formController.value.indexOf(arrayItem);

        if (index >= 0) {
            this.chipListControl.value.splice(index, 1);
            this.formController.value.splice(index, 1);
            this.chipInput.setValue(arrayItem);
            this.inputElement.nativeElement.value = arrayItem;
        }
    }

    selectChip(chip: MatChip, arrayItem: string): void {
        this.currentPosition = this.formController.value.indexOf(arrayItem);
        this.chipListControl.setValue(' ' + arrayItem + ' cancel');
        chip.select();
    }

    addOnBlur(event: FocusEvent): void {
        const target: HTMLElement = event.relatedTarget as HTMLElement;

        if (!target || target.tagName !== 'MAT-OPTION') {
            const matChipEvent: MatChipInputEvent = {
                input: this.inputElement.nativeElement,
                value: this.inputElement.nativeElement.value
            };

            this.addChip(matChipEvent);
        }
    }

    moveItem(key: KeyboardEvent, arrayItem: string): void {
        switch (key.key) {
            case ' ':
                this.currentPosition = this.formController.value.indexOf(arrayItem);
                if (!this.chipList.chips.get(this.currentPosition).selected) {
                    this.chipListControl.setValue(Object.assign([], this.formController.value));
                }
                break;
            case 'ArrowRight':
                if (this.chipList.chips.get(this.currentPosition).selected) {
                    if (this.currentPosition < this.formController.value.length - 1 && key.ctrlKey) {
                        moveItemInArray(this.formController.value, this.currentPosition, this.formController.value.length - 1);
                        this.currentPosition = this.formController.value.length - 1;
                    } else if (this.currentPosition < this.formController.value.length - 1) {
                        moveItemInArray(this.formController.value, this.currentPosition, this.currentPosition + 1);
                        this.chipList.focus();
                        this.currentPosition += 1;
                    }
                    setTimeout(() => this.chipList.chips.get(this.currentPosition).focus(), 5);
                }
                break;
            case 'ArrowLeft':
                if (this.chipList.chips.get(this.currentPosition).selected) {
                    if (this.currentPosition > 0 && key.ctrlKey) {
                        moveItemInArray(this.formController.value, this.currentPosition, 0);
                        this.currentPosition = 0;
                    } else if (this.currentPosition > 0) {
                        moveItemInArray(this.formController.value, this.currentPosition, this.currentPosition - 1);
                        this.chipList.focus();
                        this.currentPosition -= 1;
                    }
                    setTimeout(() => this.chipList.chips.get(this.currentPosition).focus(), 5);
                }
                break;
            default:
                this.chipList.focus();
                this.chipList.chips.get(this.currentPosition).focus();
                break;
        }
        this.formController.updateValueAndValidity();
    }

    deleteItem(arrayItem: string): void {
        this.formController.value.splice(this.formController.value.indexOf(arrayItem), 1);
        this.chipListControl.value.splice(this.chipListControl.value.indexOf(arrayItem), 1);
        this.formController.updateValueAndValidity();
        this.chipListControl.updateValueAndValidity();
    }

    private getAutoCompleteArray(autoCompleteName: string): void {
        this.userDataService.getAllKnowledge(autoCompleteName).subscribe((knowledge) => {
            this.setupFilter(knowledge);
        });
    }

    private setupFilter(array: string[]): void {
        this.autoCompleteArray = array;
        this.filteredAutoCompleteArray = this.chipInput.valueChanges.pipe(
            startWith(''),
            map((value) => this.filter(value))
        );
    }

    private filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.autoCompleteArray.filter((option) => option.toLowerCase().includes(filterValue));
    }
}
