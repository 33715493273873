import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UserProject } from '../../../../models/UserProject';
import { UserDataService } from '../../../../service/user-data.service';

@Component({
    selector: 'app-projects-view',
    templateUrl: './projects-view.component.html',
    styleUrls: ['./projects-view.component.scss']
})
export class ProjectsViewComponent {
    @Output() editProjectEmitter = new EventEmitter<UserProject>();
    @Output() projectEditedEmitter = new EventEmitter<FormGroup>();
    @Output() projectDeleteEmitter = new EventEmitter<UserProject>();
    @Input() projects: UserProject[];
    @Input() projectEditIndex: boolean;
    @Input() projectFormGroup: FormGroup;
    get editMode(): boolean {
        return this.userDataService.profileActionOption !== 1;
    }
    constructor(private userDataService: UserDataService) {}
}
