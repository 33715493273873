<div *ngFor="let propertyChange of propertyChangeList">
  <mat-button-toggle #propertyToggle>
    <mat-icon class="mat-icon-rtl-mirror">
      {{propertyToggle.checked ? 'expand_more' : 'chevron_right'}}
    </mat-icon>
  </mat-button-toggle>{{translations[propertyChange.property]}}
  <div *ngIf="propertyToggle.checked">
    <div [ngSwitch]="propertyChange.changeType">
      <app-compare-list *ngSwitchCase="changeType.LIST_CHANGE" [listComparison]="propertyChange"></app-compare-list>
      <app-compare-map *ngSwitchCase="changeType.MAP_CHANGE" [mapComparison]="propertyChange"></app-compare-map>
      <app-compare-string *ngSwitchCase="changeType.INITIAL_VALUE_CHANGE" [stringComparison]="propertyChange"></app-compare-string>
    </div>
  </div>
</div>
