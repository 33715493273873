import { AfterViewInit, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MatButtonToggle } from '@angular/material/button-toggle';

import { PropertiesTranslations } from '../../../../../enum/PropertiesTranslations';
import { PropertyComparison } from '../../../../../models/ComparisonModels';

@Component({
    selector: 'app-property-change',
    templateUrl: './property-change.component.html',
    styleUrls: ['./property-change.component.scss']
})
export class PropertyChangeComponent implements AfterViewInit {
    @ViewChildren('propertyToggle') propertyToggle: QueryList<MatButtonToggle>;
    @Input() propertyChangeList: PropertyComparison[];
    propertyToggleList: QueryList<MatButtonToggle>;
    translations: typeof PropertiesTranslations = PropertiesTranslations;
    changeType = {
        INITIAL_VALUE_CHANGE: 'Initial Value Change',
        LIST_CHANGE: 'List Change',
        MAP_CHANGE: 'Map Change'
    };

    ngAfterViewInit(): void {
        this.propertyToggleList = this.propertyToggle;
    }
}
