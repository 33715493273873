import { Component, Input, OnInit } from '@angular/core';

import { PropertyComparison, StringChange } from '../../../../../../models/ComparisonModels';

@Component({
    selector: 'app-compare-string',
    templateUrl: './compare-string.component.html',
    styleUrls: ['./compare-string.component.scss']
})
export class CompareStringComponent implements OnInit {
    @Input() stringComparison: PropertyComparison;
    displayedColumns: string[] = ['previous', 'after'];
    datasource: StringChange[];

    ngOnInit(): void {
        this.datasource = Array.of(this.stringComparison.stringChange);
    }
}
