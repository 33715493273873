<h1 class="section-title">Daten zum Profil</h1>
<table class="person-data-table">
  <tr>
    <td class="responsive-react-title">Titel</td>
    <app-input-field [inputFormControl]="personalProfileData.get('jobLevel')"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Technische<br>Schwerpunkte</td>
    <app-chip-list [formController]="personalProfileData.get('mainFocus')"
                   [isArray]="true">
    </app-chip-list>
  </tr>
  <tr>
    <td class="responsive-react-title">Beschreibung</td>
    <app-input-field [inputFormControl]="personalProfileData.get('description')"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Tätigkeit</td>
    <app-input-field [inputFormControl]="personalProfileData.get('occupation')"></app-input-field>
  </tr>
</table>

