import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OnlyLoggedInUsersGuard } from './auth/only-logged-in-users.guard';
import { MainPageComponent } from './components/main-page/main-page.component';
import { EmployeeServiceComponent } from './components/employee-service/employee-service.component';
import { AdminGuard } from './auth/admin.guard';
import { ProfileTemplateComponent } from './components/main-page/profile-template/profile-template.component';

const routes: Routes = [
    { path: '', redirectTo: 'home/profil', pathMatch: 'full' },
    {
        path: 'home',
        canActivate: [OnlyLoggedInUsersGuard],
        children: [
            { path: 'profil', component: ProfileTemplateComponent, data: { userstatus: 'profil' } },
            { path: 'admin', component: MainPageComponent, data: { userstatus: 'admin' }, canActivate: [AdminGuard] }
        ]
    },
    { path: 'employee', component: EmployeeServiceComponent, canActivate: [OnlyLoggedInUsersGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
