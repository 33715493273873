import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UserProfile } from '../../../../models/UserProfile';

@Component({
    selector: 'app-knowledge-edit',
    templateUrl: './knowledge-edit.component.html',
    styleUrls: ['./knowledge-edit.component.scss']
})
export class KnowledgeEditComponent {
    @Input() knowledgeForm: FormGroup;
    @Input() userProfile: UserProfile;

    constructor() {}
}
