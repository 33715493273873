import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-change-foreign-language-dialog',
    templateUrl: './change-foreign-language-dialog.component.html',
    styleUrls: ['./change-foreign-language-dialog.component.scss']
})
export class ChangeForeignLanguageDialogComponent implements OnInit {
    level: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ChangeForeignLanguageDialogComponent>) {}

    selectedLevel(value: number): string {
        let level = '';

        switch (value) {
            case 1:
                level = 'Grundkenntnisse';
                break;
            case 2:
                level = 'gute Kenntnisse';
                break;
            case 3:
                level = 'fließend';
                break;
            case 4:
                level = 'verhandlungssicher';
                break;
        }

        return level;
    }

    ngOnInit(): void {
        if (this.data.level) {
            this.level = this.data.level;
        }
    }
}
