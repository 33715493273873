import { Injectable } from '@angular/core';

import { UserProfile } from '../models/UserProfile';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    constructor() {}

    getFilterProfiles(userProfiles: UserProfile[], date: Date, selectedSkill: string, name: string): UserProfile[] {
        let profiles = userProfiles;

        if (selectedSkill !== '') {
            profiles = profiles.filter((profile) => {
                return (
                    profile.branches.some((branch) => branch.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.methodicKnowledge.some((knowledge) => knowledge.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.programmingLanguages.some((language) => language.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.tools.some((tools) => tools.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.frameworks.some((frameworks) => frameworks.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.databases.some((databases) => databases.toLowerCase().includes(selectedSkill.toLowerCase())) ||
                    profile.operatingSystems.some((system) => system.toLowerCase().includes(selectedSkill.toLowerCase()))
                );
            });
        }

        if (date) {
            profiles = profiles.filter((profile) => {
                const projectExpireDate = new Date(profile.projectExpireDate);
                const filteredDate = new Date(date);

                return filteredDate >= projectExpireDate;
            });
        }
        if (name !== '') {
            profiles = profiles.filter((profile) => {
                return (profile.firstname + ' ' + profile.lastname).toLowerCase().includes(name.toLowerCase());
            });
        }

        return profiles;
    }
}
