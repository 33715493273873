<div class="project-table-container" *ngFor="let project of projects" cdkDrag [cdkDragDisabled]="editMode || expansionPanel.expanded">
    <mat-expansion-panel #expansionPanel>
      <mat-expansion-panel-header>
        <mat-icon class="icon--padding-right">reorder</mat-icon>
        <span class="text--spacing-top">{{project.projectName}} - {{project.client}}</span>
      </mat-expansion-panel-header>
      <app-projects-edit *ngIf="projectEditIndex"
                         [projectFormGroup]="projectFormGroup"
                         (editProjectEmitter)="projectEditedEmitter.emit($event)">
      </app-projects-edit>
      <table class="project-table" *ngIf="!projectEditIndex">
        <tr>
          <td class="responsive-react-title">Projektname</td>
          <td>{{project.projectName}}</td>
        </tr>
        <tr>
          <td class="responsive-react-title">Klient</td>
          <td>{{project.client}}</td>
        </tr>
        <tr>
          <td class="responsive-react-title">Branche</td>
          <td><span *ngFor="let branch of project.branches; let isLast=last">{{branch}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Rolle im Projekt</td>
          <td>{{project.role}}</td>
        </tr>
        <tr>
          <td class="responsive-react-title">Schlüsselkentnisse</td>
          <td><span *ngFor="let keySkill of project.keySkills; let isLast=last">{{keySkill}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Kurzbeschreibung</td>
          <td>{{project.projectDescription}}</td>
        </tr>
        <tr>
          <td class="responsive-react-title">Aufgabe</td>
          <td><span *ngFor="let todo of project.todos; let isLast=last">{{todo}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Programmiersprachen</td>
          <td><span *ngFor="let programmingLanguage of project.programmingLanguages; let isLast=last">{{programmingLanguage}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Technologien / Frameworks</td>
          <td><span *ngFor="let framework of project.frameworks; let isLast=last">{{framework}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Tools / Entwicklungsumgebungen</td>
          <td><span *ngFor="let tool of project.tools; let isLast=last">{{tool}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Datenbanken / Datenbanktools</td>
          <td><span *ngFor="let database of project.databases; let isLast=last">{{database}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Betriebssysteme</td>
          <td><span *ngFor="let operatingSystem of project.operatingSystems; let isLast=last">{{operatingSystem}}{{isLast ? '' : ', '}}</span></td>
        </tr>
        <tr>
          <td class="responsive-react-title">Projektdauer</td>
          <td>{{project.projectLength}}</td>
        </tr>
        <tr>
          <td class="responsive-react-title">Teamgröße</td>
          <td>{{project.teamSize}}</td>
        </tr>
      </table>
      <button class="button-standart-positive"
              matTooltip="Dieses Projekt bearbeiten"
              (click)="editProjectEmitter.emit(project)"
              *ngIf="!projectEditIndex">
        Projektinformationen bearbeiten
      </button>
      <button class="button-standart-negative edit-f" (click)="projectDeleteEmitter.emit(project)" *ngIf="!projectEditIndex">Projekt löschen</button>
    </mat-expansion-panel>
</div>
