import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-employee-service',
    templateUrl: './employee-service.component.html',
    styleUrls: ['./employee-service.component.scss']
})
export class EmployeeServiceComponent implements OnInit {
    chosenUser: string = null;
    @Output() employeeViewEnabled = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {
        if (this.chosenUser !== null) {
            const iframe: HTMLIFrameElement = document.getElementById('employee-service') as HTMLIFrameElement;

            iframe.src = environment.employeeUrl + '?user=' + this.chosenUser + '&host=' + window.location.origin;
        } else {
            const iframe: HTMLIFrameElement = document.getElementById('employee-service') as HTMLIFrameElement;

            iframe.src = environment.employeeUrl + '?host=' + window.location.origin;
        }
        this.addNativeMessageListener();
    }

    addNativeMessageListener(): void {
        window.addEventListener(
            'message',
            (event) => {
                if (event.origin === environment.employeeUrl && event.data === 'init success') {
                    this.employeeViewEnabled.emit(false);
                } else {
                    return;
                }
            },
            false
        );
    }
}
