export class ComparisonRequest {
    previousVersion: number;
    afterVersion: number;

    constructor(previousVersion: number, afterVersion: number) {
        this.previousVersion = previousVersion;
        this.afterVersion = afterVersion;
    }
}

export class ComparisonResponse {
    profileChanges: PropertyComparison[];
    projectsChanges: ProjectComparison[];
}

export class ProjectComparison {
    projectId: number;
    projectChanges: PropertyComparison[];
}

export class PropertyComparison {
    changeType: string;
    property: string;
    listChange?: StringChange[];
    stringChange?: StringChange;
    mapChange?: MapChange;
}

export class StringChange {
    previous: string;
    after: string;
}

export class MapChange {
    key: string;
    previous: string;
    after: string;
}
