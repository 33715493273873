import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';

import { environment } from '../environments/environment';

import { KeycloakDataService } from './service/keycloak-data.service';
import { UserDataService } from './service/user-data.service';
import { UserProfile } from './models/UserProfile';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('drawer') drawer: MatDrawer;
    adminStatus: boolean;
    adminOnly = false;
    employeeViewEnabled = false;
    adminView: boolean;

    constructor(
        private keycloak: KeycloakDataService,
        private http: HttpClient,
        private userDataService: UserDataService,
        private router: Router,
        private keycloakDataService: KeycloakDataService
    ) {}

    ngOnInit(): void {
        this.adminStatus = this.keycloak.checkAdmin();
        if (
            !this.keycloak.getRole().includes('apprenticeship') &&
            !this.keycloak.getRole().includes('consultant') &&
            this.keycloak.getRole().includes('admin')
        ) {
            this.userDataService.adminView = true;
            this.adminOnly = true;
        }
        this.keycloak.getUserName().then((username) => {
            let api = 'employee';

            if (this.keycloak.getRole().includes('apprenticeship')) {
                api = 'apprenticeship';
            } else if (this.keycloak.getRole().includes('trainee')) {
                api = 'trainee';
            }
            this.http.get(environment.employeeApi + '/' + api + '/' + username).subscribe((user) => {
                if (!user['birthdate'] && !user['hiringDate'] && !user['description']) {
                    this.router.navigate(['/employee']);
                }
            });
        });

        this.userDataService.getUserProfileByKeycloakId().subscribe((keycloak: UserProfile) => this.navigateUserAfterLogin(keycloak));
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        if (event.target.innerWidth > 724) {
            this.drawer.close();
        }
    }

    closeOnClick(): void {
        this.drawer.close();
    }

    toggleAdminView(adminView: boolean): void {
        this.userDataService.adminView = adminView;
        this.adminView = adminView;
    }

    navigateUserAfterLogin(keycloak: UserProfile): void {
        if (keycloak.birthdate === undefined) {
            this.router.navigate(['employee']);
        } else if (this.adminOnly) {
            this.router.navigate(['home/admin']);
        } else {
            this.router.navigate(['home/profil']);
        }
    }

    logout(): void {
        this.keycloakDataService.logout();
    }
}
