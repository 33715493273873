<div class="sidenav-container">
  <app-nav-bar [isAdmin]="adminStatus" [adminOnly]="adminOnly" (toggleDrawer)="drawer.toggle()"></app-nav-bar>
  <mat-drawer-container class="sidenav-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="over">
      <div class="nav-content">
        <ul class="nav-list">
          <li>
            <button mat-button routerLink="/home/profil" routerLinkActive="active" *ngIf="!adminOnly" (click)="toggleAdminView(false); closeOnClick()">
              <mat-icon>open_in_browser</mat-icon>
              <span>Profil</span>
            </button>
          </li>
          <li>
            <button mat-button routerLink="/home/admin" routerLinkActive="active" *ngIf="adminStatus || adminOnly" (click)="toggleAdminView(true); closeOnClick()">
              <mat-icon>admin_panel_settings</mat-icon>
              <span>Admin</span>
            </button>
          </li>
          <li>
            <button mat-button  routerLink="/employee"  routerLinkActive="active" (click)="closeOnClick()">
              <mat-icon>face</mat-icon>
              <span>User</span>
            </button>
          </li>
          <li>
            <button mat-button class="logout" (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>Logout
            </button>
          </li>
        </ul>
      </div>
    </mat-drawer>
    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
