import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserProfile } from '../../models/UserProfile';
import { UserDataService } from '../../service/user-data.service';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
    profileChosen = false;
    currentlyViewedUser: UserProfile = null;
    profiles: UserProfile[];

    constructor(private route: ActivatedRoute, private userDataService: UserDataService) {}

    ngOnInit(): void {
        this.userDataService.adminView = true;
        this.userDataService.getAllUserProfiles().subscribe((profiles) => {
            this.profiles = profiles;
            if (this.route.snapshot.paramMap.get('user')) {
                this.changeUser(profiles.find((element) => element.keycloakId === this.route.snapshot.paramMap.get('user')));
            }
        });
    }

    changeUser(user: UserProfile): void {
        this.currentlyViewedUser = user;
        this.profileChosen = true;
    }
}
