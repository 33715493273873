<div class="page-content-container">
  <h1 class="content-title">Ausgewähltes Profil</h1>
  <p>Öffnen sie das Panel, um das von Ihnen ausgewählte Profil zu betrachten</p>
  <mat-expansion-panel class="currently-loaded-profile-panel" expanded *ngIf="currentlyViewedUser !== null">
    <mat-expansion-panel-header (click)="profileActionOption = 0">
      Profil von {{currentlyViewedUser.firstname}} {{currentlyViewedUser.lastname}} ein-/ausblenden
    </mat-expansion-panel-header>
    <div class="loaded-profile-info">
      <div class="profile-header">
        <div class="profile-header-heading">
          <h1 class="profile-header-name">{{currentlyViewedUser.firstname}} {{currentlyViewedUser.lastname}}</h1>
          <span class="profile-header-version-display"
                *ngIf="(profileActionOption ===1)">Version {{currentlyViewedUser.version + 1}} <span
            *ngIf="currentlyViewedUser.archived"><mat-icon>description</mat-icon></span></span>
          <mat-form-field appearance="fill" *ngIf="!(profileActionOption===1)"
                          class="profile-header-version-display-edit">
            <mat-label>Versionsauswahl</mat-label>
            <mat-select value="{{currentlyViewedUser.version}}" (selectionChange)="changeProfileVersion($event.value)">
              <mat-select-trigger>
                <span>Version {{currentlyViewedUser.version + 1}}
                  vom {{currentlyViewedUser.createdAt | date: 'dd.MM.yyyy'}}
                  <span
                    *ngIf="currentlyViewedUser.versionChangedBy !== ''">geändert von {{currentlyViewedUser.versionChangedBy}}</span>
                  <span *ngIf="currentlyViewedUser.archived"><mat-icon matTooltip="Diese Version ist archiviert"
                                                                       [inline]="true">description</mat-icon></span>
                </span>
              </mat-select-trigger>
              <mat-option value="{{versionInfo.version}}" *ngFor="let versionInfo of versionInfosFiltered">
                Version {{versionInfo.version + 1}} vom {{versionInfo.createdAt | date:'dd.MM.yyyy'}}
                <span *ngIf="versionInfo.versionChangedBy !== ''">geändert von {{versionInfo.versionChangedBy}}</span>
                <span *ngIf="versionInfo.archived"><mat-icon matTooltip="Diese Version ist archiviert" [inline]="true">description</mat-icon></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="profile-header-archivedFilter">
          <mat-checkbox (change)="changeVersionFilter($event.checked)" *ngIf="profileActionOption !== 1">Archivierte
            Versionen Anzeigen
          </mat-checkbox>
        </div>
        <img class="profile-header-user-image" *ngIf="currentlyViewedUserImage" [src]="currentlyViewedUserImage"
             alt="Profilbild" />
      </div>

      <app-personal-data-view *ngIf="profileActionOption===0 || !(formState === formStateEnum.KnowledgeEditFormIsOpen)"
                              [userProfile]="currentlyViewedUser"
                              [displayDates]="displayDates"></app-personal-data-view>
      <app-personal-data-edit *ngIf="profileActionOption===1 && formState === formStateEnum.KnowledgeEditFormIsOpen"
                              [userProfile]="currentlyViewedUser"
                              [personalDataFormGroup]="personalDataForm"></app-personal-data-edit>
      <app-personal-profile-data-view
        *ngIf="profileActionOption===0 || !(formState === formStateEnum.KnowledgeEditFormIsOpen)"
        [userProfile]="currentlyViewedUser"></app-personal-profile-data-view>
      <app-personal-profile-data-edit
        *ngIf="profileActionOption===1 && formState === formStateEnum.KnowledgeEditFormIsOpen"
        [personalProfileData]="profileDataForm"></app-personal-profile-data-edit>
      <app-project-template [projects]="currentlyViewedUser.userProjects" [knowledgeForm]="knowledgeForm"
                            (createProjectEmitter)="saveUserProfile(true)"
                            (deleteProjectEmitter)="deleteProject($event)"></app-project-template>
      <app-knowledge-view *ngIf="profileActionOption===0 || !(formState === formStateEnum.KnowledgeEditFormIsOpen)"
                          [userProfile]="currentlyViewedUser"></app-knowledge-view>
      <app-knowledge-edit *ngIf="profileActionOption===1 && formState === formStateEnum.KnowledgeEditFormIsOpen"
                          [userProfile]="currentlyViewedUser" [knowledgeForm]="knowledgeForm"></app-knowledge-edit>

      <h2 class="profile-administration-options-title">Informationen Bearbeiten</h2>
      <button class="edit-profile-button button-standart-positive" matTooltip="Profil bearbeiten"
              (click)="editProfile()"
              *ngIf="!(profileActionOption === 1) || formState === formStateEnum.ProjectEditFormIsOpen || formState === formStateEnum.FormFieldsClose">
        <mat-icon>create</mat-icon>
      </button>
      <button *ngIf="profileActionOption===1 && formState === formStateEnum.KnowledgeEditFormIsOpen"
              [ngClass]="{'button-standart-positive' :personalDataForm.valid && knowledgeForm.valid, 'button-standart-disabled' :!personalDataForm.valid || !knowledgeForm.valid}"
              (click)="checkProjectChange(); formState = formStateEnum.FormFieldsClose"
              [disabled]="!personalDataForm.valid || !knowledgeForm.valid">Änderungen speichern
      </button>
      <button *ngIf="profileActionOption===1 && formState === formStateEnum.KnowledgeEditFormIsOpen"
              class="button-standart-negative" (click)="cancelChanges(); formState = formStateEnum.FormFieldsClose">
        Vorgang abbrechen und Änderrungen verwerfen
      </button>

      <h2 class="profile-administration-options-title">Aktionen</h2>
      <h3 *ngIf="profileActionOption === 1">Achtung</h3>
      <p *ngIf="profileActionOption === 1">Sie können keine weiteren Aktionen durchführen, wenn Sie im Bearbeitungsmodus
        sind. Speichern Sie Ihre Änderungen oder brechen Sie den Vorgang ab.</p>
      <mat-checkbox ngModel (ngModelChange)="onCheckedImageExport($event)" [disabled]="profileActionOption === 1">Bild
        exportieren
      </mat-checkbox>
      <br>
      <mat-checkbox ngModel (ngModelChange)="onCheckedExpandDetails($event)" [disabled]="profileActionOption === 1">Projekte erweitert darstellen
      </mat-checkbox>
      <br /><br />
      <button class="edit-profile-button" matTooltip="Profil exportieren" (click)="exportProfile(currentlyViewedUser)"
              [disabled]="profileActionOption === 1 || releasedVersionInfos?.length <= 0 || exporting">
        <mat-icon>file_download</mat-icon>
      </button>
      <button class="edit-profile-button" matTooltip="Version freigeben" *ngIf="!adminView"
              (click)="releaseUserProfile()"
              [disabled]="profileActionOption === 1 || currentlyViewedUser.release || releasedVersionInfos?.length <= 0">
        <mat-icon>visibility</mat-icon>
      </button>
      <button *ngIf="!currentlyViewedUser.archived; else profileIsArchived" class="edit-profile-button"
              matTooltip="Version archivieren" (click)="archiveProfile()"
              [disabled]="profileActionOption === 1 || releasedVersionInfos?.length <= 0">
        <mat-icon>description</mat-icon>
      </button>
      <button class="edit-profile-button" matTooltip="Versionen Vergleichen" (click)="compareVersions()"
              *ngIf="releasedVersionInfos" [disabled]="profileActionOption === 1 || releasedVersionInfos.length <= 1">
        <mat-icon>compare_arrows</mat-icon>
      </button>
      <button class="edit-profile-button" matTooltip="Erinnerung Profilaktuallisierung" *ngIf="adminView"
              (click)="profileUpdateNotification()" [disabled]="profileActionOption === 1">
        <mat-icon>update</mat-icon>
      </button>
      <button class="edit-profile-button delete-button" matTooltip="Profil löschen" (click)="deleteProfile()"
              [disabled]="profileActionOption===1" *ngIf="adminView">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <ng-template #profileIsArchived>
        <button class="edit-profile-button deArchive" matTooltip="Version dearchivieren" (click)="archiveProfile()"
                [disabled]="profileActionOption === 1">
          <mat-icon>description</mat-icon>
        </button>
      </ng-template>
      <button *ngIf="currentlyViewedUser.active && adminView; else profileIsDeactivated"
              class="edit-profile-button deactivate-button" matTooltip="Profil deaktivieren"
              (click)="deactivateProfile()" [disabled]="profileActionOption === 1">
        <mat-icon>visibility_off</mat-icon>
      </button>
      <ng-template #profileIsDeactivated>
        <button class="edit-profile-button activate-button" matTooltip="Profil aktivieren" (click)="deactivateProfile()"
                [disabled]="profileActionOption === 1" *ngIf="adminView">
          <mat-icon>visibility_off</mat-icon>
        </button>
      </ng-template>
    </div>
  </mat-expansion-panel>
</div>
