import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private toastr: ToastrService) {}

    operationSuccessful(message: string): void {
        this.toastr.success(message, 'Erfolg');
    }

    warning(message: string): void {
        this.toastr.warning(message, 'Warnung');
    }

    info(message: string): void {
        this.toastr.info(message, 'Info');
    }

    error(message: string): void {
        this.toastr.error(message, 'Fehler');
    }
}
