import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
    @Input() inputFormControl: FormControl;
    @Input() placeholder: string;
    @Input() isTextField: boolean;
    @Input() isReadOnly: boolean;
    constructor() {}

    get isRequired(): boolean {
        const validator = this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';

        if (validator && validator.required) {
            return true;
        }
    }

    ngOnInit(): void {
        this.inputFormControl.markAllAsTouched();
    }
}
