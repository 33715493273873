<h1 class="section-title">Daten zur Person</h1>
<table class="person-data-table">
  <tr>
    <td class="responsive-react-title personal-data-view-title">Vorname</td>
    <td>{{userProfile.firstname}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Name</td>
    <td>{{userProfile.lastname}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Geburtsdatum</td>
    <td>{{displayDates.birthdate.toLocaleDateString()}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Abschluss</td>
    <td>{{userProfile.education}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Know-How Stufe</td>
    <td>{{userProfile.knowHowLevel}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Muttersprache</td>
    <td><span *ngFor="let language of userProfile.language; let isLast=last">{{language}}{{isLast ? '' : ', '}}</span></td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Fremdsprachen</td>
    <td><p *ngFor="let foreignLanguage of userProfile.foreignLanguage | keyvalue">{{foreignLanguage.key}} - {{foreignLanguage.value}}</p></td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Berufserfahrung</td>
    <td>
      <ng-container *ngIf="displayDates.experience.toLocaleDateString() != '1.1.1970'">
        {{displayDates.experience.toLocaleDateString()}}
      </ng-container>
      <ng-container *ngIf="displayDates.experience.toLocaleDateString() === '1.1.1970'">
        -
      </ng-container>
    </td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Weiterbildung</td>
    <td><p *ngFor="let certification of userProfile.certifications">{{certification}}</p></td>
  </tr>
  <tr *ngIf="adminView">
    <td class="responsive-react-title personal-data-view-title">Projektlaufzeit</td>
    <td>
      <ng-container *ngIf="displayDates.projectExpireDate.toLocaleDateString() != '1.1.1970'">
        {{displayDates.projectExpireDate.toLocaleDateString()}}
      </ng-container>
      <ng-container *ngIf="displayDates.projectExpireDate.toLocaleDateString() === '1.1.1970'">
        -
      </ng-container>
    </td>
  </tr>
</table>
