import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';

import { AdministrationOptions } from '../../../enum/AdministrationOptions';
import { FormState } from '../../../enum/FormState';
import { UserProfile } from '../../../models/UserProfile';
import { VersionInfo } from '../../../models/VersionInfo';
import { UserDataService } from '../../../service/user-data.service';
import { NotificationService } from '../../../service/notification.service';
import { DeleteDialogComponent } from '../html-components/delete-dialog/delete-dialog.component';
import { UserProject } from '../../../models/UserProject';
import { CompareDialogComponent } from '../html-components/compare-dialog/compare-dialog.component';

@Component({
    selector: 'app-profile-template',
    templateUrl: './profile-template.component.html',
    styleUrls: ['./profile-template.component.scss']
})
export class ProfileTemplateComponent implements OnInit, OnDestroy {
    @Input() set user(value: UserProfile) {
        if (value) {
            this.setCurrentlyViewedUser(value);
            this.getAllVersionInfo();
        }
    }

    get adminView(): boolean {
        return this.userDataService.adminView;
    }

    get profileActionOption(): AdministrationOptions {
        return this.userDataService.profileActionOption;
    }

    set profileActionOption(value) {
        this.userDataService.profileActionOption = value;
    }

    get formState(): FormState {
        return this.userDataService.formState;
    }

    set formState(value) {
        this.userDataService.formState = value;
    }

    get newestVersion(): boolean {
        return this.userDataService.newestVersion;
    }

    set newestVersion(value: boolean) {
        this.userDataService.newestVersion = value;
    }

    personalDataForm: FormGroup = new FormGroup({
        firstname: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        birthdate: new FormControl('', Validators.required),
        education: new FormControl('', Validators.required),
        knowHowLevel: new FormControl('', Validators.required),
        language: new FormControl([], Validators.required),
        foreignLanguage: new FormControl(new Map(), Validators.required),
        experience: new FormControl('', Validators.required),
        certifications: new FormControl([]),
        projectExpireDate: new FormControl('', Validators.required)
    });
    profileDataForm: FormGroup = new FormGroup({
        jobLevel: new FormControl('', Validators.required),
        mainFocus: new FormControl([], Validators.required),
        description: new FormControl('', Validators.required),
        occupation: new FormControl('', Validators.required)
    });
    knowledgeForm: FormGroup = new FormGroup({
        branches: new FormControl([], Validators.required),
        methodicKnowledge: new FormControl([], Validators.required),
        programmingLanguages: new FormControl([], Validators.required),
        frameworks: new FormControl([], Validators.required),
        tools: new FormControl([], Validators.required),
        databases: new FormControl([], Validators.required),
        operatingSystems: new FormControl([], Validators.required),
        basicKnowledge: new FormControl([], Validators.required)
    });

    userProfiles: UserProfile[] = [];
    currentlyViewedUser: UserProfile = null;
    displayDates = {
        birthdate: new Date(),
        experience: new Date(),
        projectExpireDate: new Date()
    };
    currentlyViewedUserImage: any;
    releasedVersionInfos: VersionInfo[];
    allVersionInfo: VersionInfo[];
    versionInfosFiltered: VersionInfo[] = [];
    versionFilterActivated: boolean;
    exporting: boolean;
    formStateEnum: typeof FormState = FormState;
    private originalProjectOrder: UserProject[];
    private unsubscribe$: Subject<boolean> = new Subject<boolean>();
    private expandDetails = false;

    constructor(
        public dialog: MatDialog,
        private userDataService: UserDataService,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        private notification: NotificationService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        if (!this.adminView) {
            this.personalDataForm.removeControl('projectExpireDate');
        }
        if (!this.currentlyViewedUser) {
            this.userDataService
                .getUserProfileByKeycloakId()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((user: UserProfile) => {
                    this.setCurrentlyViewedUser(user);
                    this.getAllVersionInfo();
                });
        }
    }

    changeProfileVersion(version: number): void {
        if (this.adminView) {
            this.userDataService
                .getProfileVersionAdminView(this.currentlyViewedUser.keycloakId, version)
                .subscribe((profile: UserProfile) => {
                    this.setCurrentlyViewedUser(profile);
                    this.cd.markForCheck();
                });
        } else {
            this.userDataService
                .getProfileVersion(version)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((profile: UserProfile) => {
                    this.setCurrentlyViewedUser(profile);
                    this.cd.markForCheck();
                });
        }
    }

    changeVersionFilter(filterEnabled: boolean): void {
        if (!filterEnabled) {
            this.versionFilterActivated = false;
            this.versionInfosFiltered = [];
            this.releasedVersionInfos.forEach((versionInfo) => {
                if (versionInfo.archived === false) {
                    this.versionInfosFiltered.push(versionInfo);
                }
            });
            if (this.currentlyViewedUser.archived) {
                this.changeProfileVersion(this.versionInfosFiltered[0].version);
            }
        } else {
            this.versionFilterActivated = true;
            this.versionInfosFiltered = Object.assign([], this.releasedVersionInfos);
        }
    }

    archiveProfile(): void {
        if (this.currentlyViewedUser.archived === true) {
            this.currentlyViewedUser.archived = false;
            this.releasedVersionInfos[this.currentlyViewedUser.version].archived = false;
            this.userDataService
                .setProfileVersionArchiver(this.currentlyViewedUser.keycloakId, this.currentlyViewedUser.version)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.notification.info('Version wurde Dearchiviert');
                });
        } else {
            this.currentlyViewedUser.archived = true;
            this.releasedVersionInfos[this.currentlyViewedUser.version].archived = true;
            this.userDataService
                .setProfileVersionArchiver(this.currentlyViewedUser.keycloakId, this.currentlyViewedUser.version)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.notification.info('Version wurde Archiviert');
                });
        }
        if (!this.versionFilterActivated) {
            this.versionInfosFiltered = [];
            this.releasedVersionInfos.forEach((versionInfo) => {
                if (versionInfo.archived === false) {
                    this.versionInfosFiltered.push(versionInfo);
                }
            });
            this.userDataService
                .getProfileVersion(this.versionInfosFiltered[0].version)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((profile: UserProfile) => {
                    this.setCurrentlyViewedUser(profile);
                });
        } else {
            this.versionInfosFiltered = Object.assign([], this.releasedVersionInfos);
        }
    }

    setCurrentlyViewedUser(user: UserProfile): void {
        this.userDataService.profileActionOption = AdministrationOptions.None;
        this.currentlyViewedUser = user;
        this.userDataService.keyCloakName = user.keycloakId;
        this.userDataService.username = user.firstname + ' ' + user.lastname;
        this.originalProjectOrder = this.currentlyViewedUser.userProjects.map((x) => Object.assign({}, x));
        this.currentlyViewedUser.foreignLanguage = this.userDataService.foreignLanguageObjectToMap(user.foreignLanguage);
        this.userDataService.addSkillsToKnowledge(this.currentlyViewedUser);
        this.displayDates.birthdate = new Date(this.currentlyViewedUser.birthdate);
        this.displayDates.experience = new Date(this.currentlyViewedUser.experience);
        this.displayDates.projectExpireDate = new Date(this.currentlyViewedUser.projectExpireDate);
        window.scroll(0, 0);
        this.userDataService
            .getUserImageById(this.currentlyViewedUser.keycloakId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: Blob) => {
                if (value.size === 0) {
                    this.currentlyViewedUserImage = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/userProfileFallbackImage.jpg');
                } else {
                    this.createImageFromBlob(value);
                }
            });
        this.cd.markForCheck();
        this.initializeProfileForm();
    }

    exportProfile(profile: UserProfile): void {
        this.exporting = true;
        this.currentlyViewedUser.foreignLanguage = this.userDataService.foreignLanguageMapToObject(
            this.currentlyViewedUser.foreignLanguage
        );
        this.userDataService.exportProfile(profile, this.expandDetails).subscribe((fileData: Blob) => {
            this.exporting = false;
            const userImage: Blob = new Blob([fileData], { type: 'text/plain;charset=utf-8' });

            saveAs(userImage, 'Profil_' + profile.firstname + '_' + profile.lastname + '.docx');
            this.currentlyViewedUser.foreignLanguage = this.userDataService.foreignLanguageObjectToMap(
                this.currentlyViewedUser.foreignLanguage
            );
        });
    }

    profileUpdateNotification(): void {
        this.userDataService
            .profileUpdateNotification(this.currentlyViewedUser.keycloakId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.notification.info('Mail wurde versendet.');
            });
    }

    onCheckedImageExport(isChecked: boolean): void {
        this.currentlyViewedUser.exportImage = isChecked;
    }

    onCheckedExpandDetails(isChecked: boolean): void {
        this.expandDetails = isChecked;
    }

    compareVersions(): void {
        this.dialog.open(CompareDialogComponent, {
            width: '80%',
            data: this.allVersionInfo
        });
    }

    deleteProfile(): void {
        const dialogueRef = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            data: 'Sind Sie sich sicher, dass Sie dieses Profil löschen möchten?'
        });

        dialogueRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((answer: boolean) => {
                if (answer === true) {
                    this.userDataService
                        .deleteProfile(this.currentlyViewedUser.keycloakId)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                            this.notification.operationSuccessful('Profildaten erfolgreich gelöscht');
                        });
                    this.userDataService
                        .getAllUserProfiles()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((profiles) => {
                            this.userProfiles = profiles;
                            this.currentlyViewedUser = this.userProfiles[0];
                        });
                }
            });
    }

    createImageFromBlob(image: Blob): void {
        const reader = new FileReader();

        reader.onloadend = (): void => {
            if (typeof reader.result === 'string') {
                this.currentlyViewedUserImage = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
            }
        };
        reader.readAsDataURL(image);
    }

    deactivateProfile(): void {
        this.currentlyViewedUser.active = !this.currentlyViewedUser.active;
        this.userDataService
            .saveUserProfile(this.currentlyViewedUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.currentlyViewedUser.active) {
                    this.notification.operationSuccessful('Profil erfolgreich aktiviert');
                } else {
                    this.notification.operationSuccessful('Profil erfolgreich deaktiviert');
                }
            });
    }

    checkProjectChange(): void {
        if (!this.userDataService.isSameArray(this.currentlyViewedUser.userProjects, this.originalProjectOrder, true)) {
            this.saveUserProfile(true);
        } else {
            this.saveUserProfile(false);
        }
    }

    saveUserProfile(projectChange: boolean): void {
        if (
            this.detectFormChanges(this.personalDataForm) ||
            this.detectFormChanges(this.knowledgeForm) ||
            projectChange ||
            this.detectFormChanges(this.profileDataForm)
        ) {
            this.updatePersonalData();
            this.updateProfileData();
            this.updateKnowledge();
            if (!this.adminView) {
                this.currentlyViewedUser.release = false;
            }
            this.userDataService
                .saveUserProfile(this.currentlyViewedUser)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.notification.operationSuccessful('Profildaten erfolgreich aktualisiert');
                    this.getAllVersionInfo();
                });
        } else {
            this.notification.info('Es wurden keine Änderungen gemacht');
        }
        this.knowledgeForm.markAsPristine();
        this.personalDataForm.markAsPristine();
        this.profileDataForm.markAsPristine();
        this.cd.markForCheck();
        this.userDataService.profileActionOption = 0;
    }

    deleteProject(projects: UserProject[]): void {
        this.currentlyViewedUser.userProjects = projects;
        this.saveUserProfile(true);
    }

    cancelChanges(): void {
        this.initializeProfileForm();
        this.userDataService.profileActionOption = 0;
    }

    releaseUserProfile(): void {
        this.currentlyViewedUser.release = true;
        this.userDataService
            .releaseUserProfile(this.currentlyViewedUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.notification.operationSuccessful('Version erfolgreich Freigegeben');
                this.currentlyViewedUser.foreignLanguage = this.userDataService.foreignLanguageObjectToMap(
                    this.currentlyViewedUser.foreignLanguage
                );
            });
    }

    editProfile(): void {
        if (this.newestVersion) {
            this.profileActionOption = 1;
            this.formState = this.formStateEnum.KnowledgeEditFormIsOpen;
        } else {
            this.notification.error(
                'Sie können keine neue Version erstellen. ' +
                    this.userDataService.username +
                    ' hat noch eine nicht freigegebene Profilversion.'
            );
        }
    }

    private initializeProfileForm(): void {
        this.writeIntoForm(this.personalDataForm);
        this.writeIntoForm(this.knowledgeForm);
        this.writeIntoForm(this.profileDataForm);
    }

    private updatePersonalData(): void {
        for (const valueKey in this.personalDataForm.value) {
            if (this.personalDataForm.value.hasOwnProperty(valueKey)) {
                this.currentlyViewedUser[valueKey] = this.personalDataForm.value[valueKey];
            }
        }
    }

    private updateProfileData(): void {
        for (const valueKey in this.profileDataForm.value) {
            if (this.profileDataForm.value.hasOwnProperty(valueKey)) {
                this.currentlyViewedUser[valueKey] = this.profileDataForm.value[valueKey];
            }
        }
    }

    private updateKnowledge(): void {
        for (const valueKey in this.knowledgeForm.value) {
            if (this.knowledgeForm.value.hasOwnProperty(valueKey)) {
                this.currentlyViewedUser[valueKey] = this.knowledgeForm.value[valueKey];
            }
        }
    }

    private detectFormChanges(inputFormGroup: FormGroup): boolean {
        for (const valueKey in inputFormGroup.value) {
            if (inputFormGroup.value.hasOwnProperty(valueKey)) {
                if (inputFormGroup.value[valueKey] instanceof Map) {
                    if (!this.userDataService.isSameMap(inputFormGroup.value[valueKey], this.currentlyViewedUser[valueKey])) {
                        return true;
                    }
                } else if (inputFormGroup.value[valueKey] instanceof Array) {
                    if (!this.userDataService.isSameArray(inputFormGroup.value[valueKey], this.currentlyViewedUser[valueKey])) {
                        return true;
                    }
                } else {
                    if (inputFormGroup.value[valueKey] !== this.currentlyViewedUser[valueKey]) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private getAllVersionInfo(): void {
        if (this.adminView) {
            this.userDataService
                .getAllVersionInfosById(this.currentlyViewedUser.keycloakId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((versionInfos: VersionInfo[]) => {
                    this.setVersionInfo(versionInfos);
                });
        } else {
            this.userDataService
                .getAllVersionInfosFromUser()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((versionInfos: VersionInfo[]) => {
                    this.setVersionInfo(versionInfos);
                });
        }
    }

    private setVersionInfo(versionInfos: VersionInfo[]): void {
        this.allVersionInfo = versionInfos;
        if (this.adminView) {
            this.releasedVersionInfos = this.allVersionInfo.filter((version) => version.released === true);
            this.newestVersion = this.allVersionInfo.length === this.releasedVersionInfos.length;
        } else {
            this.releasedVersionInfos = this.allVersionInfo;
            this.newestVersion = true;
        }
        this.cd.markForCheck();
        this.changeProfileVersion(
            this.releasedVersionInfos.reduce((prev: VersionInfo, current: VersionInfo) => {
                return prev.version > current.version ? prev : current;
            }).version
        );
        this.changeVersionFilter(this.versionFilterActivated);
        this.currentlyViewedUser.foreignLanguage = this.userDataService.foreignLanguageObjectToMap(
            this.currentlyViewedUser.foreignLanguage
        );
        this.cancelChanges();
    }

    private writeIntoForm(inputFormGroup): void {
        for (const valueKey in inputFormGroup.value) {
            if (inputFormGroup.value.hasOwnProperty(valueKey)) {
                if (this.currentlyViewedUser[valueKey] !== null) {
                    if (this.currentlyViewedUser[valueKey] instanceof Array) {
                        inputFormGroup.get(valueKey).setValue(Object.assign([], this.currentlyViewedUser[valueKey]));
                    } else if (
                        this.currentlyViewedUser[valueKey] instanceof Object &&
                        !(this.currentlyViewedUser[valueKey] instanceof Date)
                    ) {
                        inputFormGroup.get(valueKey).setValue(new Map<string, string>(this.currentlyViewedUser[valueKey]));
                    } else {
                        inputFormGroup.get(valueKey).setValue(this.currentlyViewedUser[valueKey]);
                    }
                }
            }
        }
    }
}
