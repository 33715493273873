import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormState } from '../../../../enum/FormState';
import { UserDataService } from '../../../../service/user-data.service';
import { AdministrationOptions } from '../../../../enum/AdministrationOptions';

@Component({
    selector: 'app-projects-create',
    templateUrl: './projects-create.component.html',
    styleUrls: ['./projects-create.component.scss']
})
export class ProjectsCreateComponent {
    @Output() createProjectEmitter = new EventEmitter<FormGroup>();
    @Input() projectFormGroup: FormGroup;
    @Input() knowledgeForm: FormGroup;
    formStateEnum: typeof FormState = FormState;

    constructor(private userDataService: UserDataService) {}

    get formState(): FormState {
        return this.userDataService.formState;
    }
    set formState(value) {
        this.userDataService.formState = value;
    }

    get profileActionOption(): AdministrationOptions {
        return this.userDataService.profileActionOption;
    }
    set profileActionOption(value) {
        this.userDataService.profileActionOption = value;
    }
}
