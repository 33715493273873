<h1 class="section-title">Daten zum Profil</h1>
<table class="person-data-table">
  <tr>
    <td class="responsive-react-title personal-data-view-title">Titel</td>
    <td>{{userProfile.jobLevel}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Technischer Schwerpunkt</td>
    <td><span *ngFor="let focus of userProfile.mainFocus; let isLast=last">{{focus}}{{isLast ? '' : ', '}}</span>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Beschreibung</td>
    <td>{{userProfile.description}}</td>
  </tr>
  <tr>
    <td class="responsive-react-title personal-data-view-title">Tätigkeit</td>
    <td>{{userProfile.occupation}}</td>
  </tr>
</table>
