<mat-form-field *ngIf="isArray" class="chip-list-form">
  <mat-chip-list #chipList [required]="isRequired" [formControl]="chipListControl">
    <mat-chip #chip="matChip" *ngFor="let arrayItem of formController.value" [removable]="true"
              (dblclick)="editChip(arrayItem)" (keyup)="moveItem($event, arrayItem)"
              (removed)="deleteItem(arrayItem)" (click)="selectChip(chip, arrayItem)">
      {{arrayItem}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input placeholder="Eintrag hinzufügen"
           autocomplete="off"
           #inputElement
           [matChipInputFor]="chipList"
           [formControl]="chipInput"
           [matAutocomplete]="auto"
           (matChipInputTokenEnd)="addChip($event)"
           [matChipInputAddOnBlur]="false"
           (blur)=addOnBlur($event)
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="toggleSelection($event.option.viewValue, formController)">
    <mat-option *ngFor="let autoCompleteVariable of filteredAutoCompleteArray | async" [value]="autoCompleteVariable">
      <div>
        <mat-checkbox [checked]="formController.value.includes(autoCompleteVariable)"
                      (change)="toggleSelection(autoCompleteVariable, formController)"
                      (click)="$event.stopPropagation()">
          {{autoCompleteVariable}}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="chip-list-form" *ngIf="isMap">
  <mat-chip-list #mapList [formControl]="formController">
    <mat-chip *ngFor="let mapItem of formController.value | keyvalue: unsorted"
              (click)="openChangeLanguageDialog(mapItem.key, mapItem.value)"
              [removable]="true"
              (removed)="formController.value.delete(mapItem.key); formController.updateValueAndValidity();">
      {{mapItem.key}} - {{mapItem.value}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input placeholder="Eintrag hinzufügen"
           [matChipInputFor]="mapList"
           [formControl]="chipInput"
           (focusout)="openChangeLanguageDialog(chipInput.value, 'Grundkenntnisse')"
           (matChipInputTokenEnd)="openChangeLanguageDialog(chipInput.value, 'Grundkenntnisse')"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           autocomplete="off"
            #foreignLanguage>
  </mat-chip-list>
</mat-form-field>
