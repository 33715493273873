import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { UserProject } from '../../../../models/UserProject';
import { UserDataService } from '../../../../service/user-data.service';
import { FormState } from '../../../../enum/FormState';
import { NotificationService } from '../../../../service/notification.service';

@Component({
    selector: 'app-project-template',
    templateUrl: './project-template.component.html',
    styleUrls: ['./project-template.component.scss']
})
export class ProjectTemplateComponent implements OnInit {
    @Input() projects: UserProject[];
    @Input() knowledgeForm: FormGroup;
    @Output() createProjectEmitter = new EventEmitter<void>();
    @Output() deleteProjectEmitter = new EventEmitter<UserProject[]>();
    projectCreateIndex = false;
    projectFormGroup: FormGroup;
    project: UserProject;
    formStateEnum: typeof FormState = FormState;

    projectEditor = {
        projectSearchCriteria: new FormControl('')
    };
    projectEditIndex = false;

    constructor(private fb: FormBuilder, private userDataService: UserDataService, private notification: NotificationService) {}

    ngOnInit(): void {
        this.initializeProjectCreator();
    }

    get formState(): FormState {
        return this.userDataService.formState;
    }
    set formState(value) {
        this.userDataService.formState = value;
    }

    get newestVersion(): boolean {
        return this.userDataService.newestVersion;
    }

    getFilteredProjects(): UserProject[] {
        return this.projects.filter((project) => project.projectName.includes(this.projectEditor.projectSearchCriteria.value));
    }

    openProjectCreateForm(): void {
        this.initializeProjectCreator();
        this.projectCreateIndex = true;
    }

    createProjectEvent(projectFormGroup: FormGroup): void {
        if (projectFormGroup) {
            this.projects.push(projectFormGroup.value);
            this.createProjectEmitter.emit();
        }
        this.projectCreateIndex = false;

        this.compareAndAddToKnowledge(this.knowledgeForm.value.branches, projectFormGroup.value.branches);
        this.compareAndAddToKnowledge(this.knowledgeForm.value.programmingLanguages, projectFormGroup.value.programmingLanguages);
        this.compareAndAddToKnowledge(this.knowledgeForm.value.frameworks, projectFormGroup.value.frameworks);
        this.compareAndAddToKnowledge(this.knowledgeForm.value.tools, projectFormGroup.value.tools);
        this.compareAndAddToKnowledge(this.knowledgeForm.value.databases, projectFormGroup.value.databases);
        this.compareAndAddToKnowledge(this.knowledgeForm.value.operatingSystems, projectFormGroup.value.operatingSystems);
    }

    compareAndAddToKnowledge(userKnowledge: string[], projectKnowledge: string[]): void {
        for (const knowledge of projectKnowledge) {
            if (!userKnowledge.includes(knowledge)) {
                userKnowledge.push(knowledge);
            }
        }
    }

    editProjectEvent(projectFormGroup: FormGroup): void {
        if (projectFormGroup) {
            if (this.detectProjectChanges(projectFormGroup)) {
                for (const projectFormGroupKey in this.project) {
                    if (this.project.hasOwnProperty(projectFormGroupKey)) {
                        this.project[projectFormGroupKey] = projectFormGroup.value[projectFormGroupKey];
                    }
                }
                this.createProjectEmitter.emit();
            }
        }
        this.projectEditIndex = false;
    }

    deleteProject(project: UserProject): void {
        if (this.newestVersion) {
            this.projects = this.projects.filter((singleProject) => singleProject !== project);
            this.deleteProjectEmitter.emit(this.projects);
        } else {
            this.notification.error(
                'Sie können keine neue Version erstellen. ' +
                    this.userDataService.username +
                    ' hat noch eine nicht freigegebene Profilversion.'
            );
        }
    }

    editProject(project: UserProject): void {
        if (this.newestVersion) {
            this.project = project;
            this.setFormGroupValues(project);
            this.projectEditIndex = true;
        } else {
            this.notification.error(
                'Sie können keine neue Version erstellen. ' +
                    this.userDataService.username +
                    ' hat noch eine nicht freigegebene Profilversion.'
            );
        }
    }

    dropProject(event: CdkDragDrop<UserProject[]>): void {
        moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
    }

    private initializeProjectCreator(): void {
        this.projectFormGroup = this.fb.group({
            projectName: new FormControl('', Validators.required),
            client: new FormControl('', Validators.required),
            branches: new FormControl([], Validators.required),
            role: new FormControl('', Validators.required),
            keySkills: new FormControl([], Validators.required),
            projectDescription: new FormControl('', Validators.required),
            todos: new FormControl([], Validators.required),
            programmingLanguages: new FormControl([]),
            frameworks: new FormControl([]),
            tools: new FormControl([]),
            databases: new FormControl([]),
            operatingSystems: new FormControl([]),
            projectLength: new FormControl('', Validators.required),
            teamSize: new FormControl('', Validators.required)
        });
    }

    private setFormGroupValues(project: UserProject): void {
        for (const projectKey in project) {
            if (project.hasOwnProperty(projectKey) && project[projectKey] !== null && project[projectKey] !== undefined) {
                if (project[projectKey] instanceof Array) {
                    this.projectFormGroup.get(projectKey).setValue(Object.assign([], project[projectKey]));
                } else {
                    this.projectFormGroup.get(projectKey).setValue(project[projectKey]);
                }
            }
        }
    }

    private detectProjectChanges(projectFormGroup: FormGroup): boolean {
        for (const projectFormGroupKey in projectFormGroup.value) {
            if (projectFormGroup.value.hasOwnProperty(projectFormGroupKey)) {
                if (projectFormGroup.value[projectFormGroupKey] instanceof Array) {
                    if (!this.userDataService.isSameArray(projectFormGroup.value[projectFormGroupKey], this.project[projectFormGroupKey])) {
                        return true;
                    }
                } else {
                    if (projectFormGroup.value[projectFormGroupKey] !== this.project[projectFormGroupKey]) {
                        return true;
                    }
                }
            }
        }
    }
}
