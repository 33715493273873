<h1 class="section-title">Fachwissen / Kenntnisse / Schwerpunkte</h1>
<table class="person-data-table">
  <dl>
    <dt><h3 class="responsive-react-title">Programmiersprachen</h3></dt>
    <dd class="array-display-row"><span *ngFor="let programmingLanguage of userProfile.programmingLanguages; let isLast=last">{{programmingLanguage}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Technologien / Frameworks</h3></dt>
    <dd class="array-display-row"><span *ngFor="let framework of userProfile.frameworks; let isLast=last">{{framework}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Tools / Entwicklungsumgebungen</h3></dt>
    <dd class="array-display-row"><span *ngFor="let tool of userProfile.tools; let isLast=last">{{tool}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Datenbanken / Datenbanktools</h3></dt>
    <dd class="array-display-row"><span *ngFor="let database of userProfile.databases; let isLast=last">{{database}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Betriebssysteme</h3></dt>
    <dd class="array-display-row"><span *ngFor="let operatingSystem of userProfile.operatingSystems; let isLast=last">{{operatingSystem}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Grundkenntnisse</h3></dt>
    <dd class="array-display-row"><span *ngFor="let basicKnowledge of userProfile.basicKnowledge; let isLast=last">{{basicKnowledge}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Methodisches Wissen</h3></dt>
    <dd class="array-display-row"><span *ngFor="let methodicalKnowledge of userProfile.methodicKnowledge; let isLast=last">{{methodicalKnowledge}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
  <dl>
    <dt><h3 class="responsive-react-title">Branchenerfahrung</h3></dt>
    <dd class="array-display-row"><span *ngFor="let branch of userProfile.branches; let isLast=last">{{branch}}{{isLast ? '' : ', '}}</span></dd>
  </dl>
</table>

