<table mat-table [dataSource]="datasource">
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef> Sprache </th>
    <td mat-cell *matCellDef="let element">{{element.key}}</td>
  </ng-container>
  <ng-container matColumnDef="previous">
    <th mat-header-cell *matHeaderCellDef> Von </th>
    <td mat-cell *matCellDef="let element"><div *ngIf="element.previous; else added">{{translations[element.previous]}}</div><ng-template #added><div style="color: green;">Neu Hinzugefügt</div></ng-template></td>
  </ng-container>
  <ng-container matColumnDef="after">
    <th mat-header-cell *matHeaderCellDef> Zu </th>
    <td mat-cell *matCellDef="let element"><div *ngIf="element.after; else removed">{{translations[element.after]}}</div><ng-template #removed><div style="color: red">Gelöscht</div></ng-template></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
