import { Component, Input } from '@angular/core';

import { UserProfile } from '../../../../models/UserProfile';

@Component({
    selector: 'app-personal-profile-data-view',
    templateUrl: './personal-profile-data-view.component.html',
    styleUrls: ['./personal-profile-data-view.component.scss']
})
export class PersonalProfileDataViewComponent {
    @Input() userProfile: UserProfile;
}
