import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

import { environment } from '../environments/environment';

import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { UserDataService } from './service/user-data.service';
import { KeycloakDataService } from './service/keycloak-data.service';
import { AppComponent } from './app.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { KnowledgeEditComponent } from './components/main-page/knowledge/knowledge-edit/knowledge-edit.component';
import { KnowledgeViewComponent } from './components/main-page/knowledge/knowledge-view/knowledge-view.component';
import { PersonalDataViewComponent } from './components/main-page/personal-data/personal-data-view/personal-data-view.component';
import { PersonalDataEditComponent } from './components/main-page/personal-data/personal-data-edit/personal-data-edit.component';
import { ProjectsViewComponent } from './components/main-page/projects/projects-view/projects-view.component';
import { ProjectsEditComponent } from './components/main-page/projects/projects-edit/projects-edit.component';
import { ChipListComponent } from './components/main-page/html-components/chip-list/chip-list.component';
import { InputFieldComponent } from './components/main-page/html-components/input-field/input-field.component';
// eslint-disable-next-line max-len
import { ChangeForeignLanguageDialogComponent } from './components/main-page/personal-data/personal-data-edit/change-foreign-language-dialog/change-foreign-language-dialog.component';
import { ProjectTemplateComponent } from './components/main-page/projects/project-template/project-template.component';
import { ProjectsCreateComponent } from './components/main-page/projects/projects-create/projects-create.component';
import { ProfileTemplateComponent } from './components/main-page/profile-template/profile-template.component';
import { ProfilesOverviewComponent } from './components/main-page/profiles-overview/profiles-overview.component';
import { ProjectFormComponent } from './components/main-page/projects/project-form/project-form.component';
import { DeleteDialogComponent } from './components/main-page/html-components/delete-dialog/delete-dialog.component';
import { EmployeeServiceComponent } from './components/employee-service/employee-service.component';
import { AppRoutingModule } from './app-routing.module';
import { CompareDialogComponent } from './components/main-page/html-components/compare-dialog/compare-dialog.component';
// eslint-disable-next-line max-len
import { CompareStringComponent } from './components/main-page/html-components/compare-dialog/property-change/compare-string/compare-string.component';
// eslint-disable-next-line max-len
import { CompareListComponent } from './components/main-page/html-components/compare-dialog/property-change/compare-list/compare-list.component';
// eslint-disable-next-line max-len
import { CompareMapComponent } from './components/main-page/html-components/compare-dialog/property-change/compare-map/compare-map.component';
import { PropertyChangeComponent } from './components/main-page/html-components/compare-dialog/property-change/property-change.component';
// eslint-disable-next-line max-len
import { PersonalProfileDataViewComponent } from './components/main-page/personal-profile-data/personal-profile-data-view/personal-profile-data-view.component';
// eslint-disable-next-line max-len
import { PersonalProfileDataEditComponent } from './components/main-page/personal-profile-data/personal-profile-data-edit/personal-profile-data-edit.component';

// Initialize Keycloak object
export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                await keycloak.init({
                    // Authorization provider information, reference to environment.ts
                    config: {
                        url: environment.keycloak.issuer,
                        realm: environment.keycloak.realm,
                        clientId: environment.keycloak.clientId
                    },
                    loadUserProfileAtStartUp: true,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: true,
                        checkLoginIframeInterval: 25
                    }
                });
                // @ts-ignore
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}

@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        MainPageComponent,
        KnowledgeEditComponent,
        KnowledgeViewComponent,
        PersonalDataViewComponent,
        PersonalDataEditComponent,
        ProjectsViewComponent,
        ProjectsEditComponent,
        DeleteDialogComponent,
        ChipListComponent,
        InputFieldComponent,
        ChangeForeignLanguageDialogComponent,
        ProjectTemplateComponent,
        ProjectsCreateComponent,
        ProfileTemplateComponent,
        ProfilesOverviewComponent,
        ProjectFormComponent,
        EmployeeServiceComponent,
        CompareDialogComponent,
        CompareStringComponent,
        CompareListComponent,
        CompareMapComponent,
        PropertyChangeComponent,
        PersonalProfileDataViewComponent,
        PersonalProfileDataEditComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        MatSliderModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatTableModule,
        MatCheckboxModule,
        KeycloakAngularModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        MatChipsModule,
        DragDropModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatRadioModule,
        AppRoutingModule,
        MatSidenavModule,
        MatTreeModule,
        MatButtonToggleModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [KeycloakService],
            multi: true
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true }
        },
        HttpClient,
        UserDataService,
        KeycloakDataService,
        KeycloakService,
        AppComponent,
        NavBarComponent,
        MatDialog,
        MatDatepickerModule
    ],
    bootstrap: [AppComponent]
})
// @ts-ignore
export class AppModule {}
