import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { UserProfile } from '../../../models/UserProfile';
import { FilterService } from '../../../service/filter.service';
import { UserDataService } from '../../../service/user-data.service';

@Component({
    selector: 'app-profile-overview',
    templateUrl: './profiles-overview.component.html',
    styleUrls: ['./profiles-overview.component.scss']
})
export class ProfilesOverviewComponent implements OnInit {
    @Output() changeUserProfile = new EventEmitter<UserProfile>();
    @Input() userProfiles: UserProfile[] = [];
    currentlyViewedUser: UserProfile = null;
    filteredProfiles: UserProfile[] = [];
    skill: string[] = [];
    filteredSkill: Observable<string[]>;
    filteredDate: FormControl = new FormControl('');
    selectedSkill: FormControl = new FormControl('');
    userSearchCriteria = new FormControl('');

    constructor(private filterService: FilterService, private userDataService: UserDataService) {}

    private static getDateDifference(expireDate: Date, currentDate: Date): number {
        return Math.floor(
            (Date.UTC(expireDate.getFullYear(), expireDate.getMonth(), expireDate.getDate()) -
                Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) /
                (1000 * 60 * 60 * 24)
        );
    }

    ngOnInit(): void {
        this.userProfiles = this.userProfiles.sort((a: UserProfile, b: UserProfile) => (a.firstname < b.firstname ? -1 : 1));
        this.filteredProfiles = this.userProfiles;
        this.filteredSkill = this.selectedSkill.valueChanges.pipe(
            startWith(''),
            map((value) => this.skillFilter(value))
        );
    }

    filterProfiles(): void {
        if (!(this.selectedSkill.value === '' && this.filteredDate.value === '' && this.userSearchCriteria.value === '')) {
            this.filteredProfiles = this.filterService.getFilterProfiles(
                this.userProfiles,
                this.filteredDate.value,
                this.selectedSkill.value,
                this.userSearchCriteria.value
            );
        } else {
            this.filteredProfiles = this.userProfiles;
        }
    }

    skillFilter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.skill.filter((option) => option.toLowerCase().startsWith(filterValue));
    }

    resetDateFilter(): void {
        this.filteredDate.setValue('');
        this.filterProfiles();
    }

    setCurrentlyViewedUser(user: UserProfile): void {
        this.userDataService.getAllUserProfiles().subscribe((users: UserProfile[]) => {
            this.userProfiles = users.sort((a: UserProfile, b: UserProfile) => (a.firstname < b.firstname ? -1 : 1));
            this.filteredProfiles = this.userProfiles;
            this.changeUserProfile.emit(user);
        });
    }

    setAvailabilityColor(user: UserProfile): object {
        let styles;
        const dateDifference = ProfilesOverviewComponent.getDateDifference(new Date(user.projectExpireDate), new Date());

        if (user.active === false) {
            styles = { 'background-color': 'darkgray', 'border-color': 'gray' };
        } else if (dateDifference > 42) {
            styles = {};
        } else if (dateDifference <= 42 && dateDifference >= 1) {
            styles = { 'background-color': 'orange', 'border-color': 'orange' };
        } else if (dateDifference <= 0) {
            styles = { 'background-color': '#F53744', 'border-color': '#F53744' };
        }

        return styles;
    }

    setAvailabilityText(user: UserProfile): string {
        let text;
        const dateDifference = ProfilesOverviewComponent.getDateDifference(new Date(user.projectExpireDate), new Date());

        if (user.active === false) {
            text = 'User ist Deaktiviert';
        } else if (dateDifference > 42) {
            text = 'Im Projekt';
        } else if (dateDifference <= 42 && dateDifference >= 1) {
            text = ' Projekt Ende in weniger als 6 Wochen';
        } else if (dateDifference <= 0) {
            text = 'in keinem Projekt';
        }

        return text;
    }
}
