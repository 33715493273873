import { Component, Input } from '@angular/core';

import { UserProfile } from '../../../../models/UserProfile';
import { UserDataService } from '../../../../service/user-data.service';

@Component({
    selector: 'app-personal-data-view',
    templateUrl: './personal-data-view.component.html',
    styleUrls: ['./personal-data-view.component.scss']
})
export class PersonalDataViewComponent {
    @Input() userProfile: UserProfile;
    @Input() displayDates;
    get adminView(): boolean {
        return this.userDataService.adminView;
    }

    constructor(private userDataService: UserDataService) {}
}
