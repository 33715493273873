import { Component, Input } from '@angular/core';

import { UserProfile } from '../../../../models/UserProfile';

@Component({
    selector: 'app-knowledge-view',
    templateUrl: './knowledge-view.component.html',
    styleUrls: ['./knowledge-view.component.scss']
})
export class KnowledgeViewComponent {
    @Input() userProfile: UserProfile;

    constructor() {}
}
