<table mat-table [dataSource]="datasource">
  <ng-container matColumnDef="previous">
    <th mat-header-cell *matHeaderCellDef> Von </th>
    <td mat-cell *matCellDef="let element"><div *ngIf="element.previous; else newString">{{element.previous}}</div><ng-template #newString><div style="color: green">Neu Erstellt</div></ng-template></td>
  </ng-container>
  <ng-container matColumnDef="after">
    <th mat-header-cell *matHeaderCellDef> Zu </th>
    <td mat-cell *matCellDef="let element"><div *ngIf="element.after; else deletedString">{{element.after}}</div><ng-template #deletedString><div style="color: red">Gelöscht</div></ng-template></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
