import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { UserProfile } from '../models/UserProfile';
import { environment } from '../../environments/environment';
import { VersionInfo } from '../models/VersionInfo';
import { AdministrationOptions } from '../enum/AdministrationOptions';
import { FormState } from '../enum/FormState';
import { ComparisonRequest, ComparisonResponse } from '../models/ComparisonModels';

@Injectable()
export class UserDataService {
    private knowledgeSkills = {
        programmingLanguages: [],
        frameworks: [],
        tools: [],
        databases: [],
        operatingSystems: []
    };
    private HEADER;
    private skillList = ['programmingLanguages', 'frameworks', 'tools', 'databases', 'operatingSystems'];
    private _keyCloakName: string;
    private _username: string;
    private _adminView: boolean;
    private _profileActionOption: AdministrationOptions = AdministrationOptions.None;
    private _formState: FormState = FormState.FormFieldsClose;
    private _newestVersion: boolean;

    constructor(private httpClient: HttpClient) {}

    get profileActionOption(): AdministrationOptions {
        return this._profileActionOption;
    }

    set profileActionOption(value: AdministrationOptions) {
        this._profileActionOption = value;
    }

    get keyCloakName(): string {
        return this._keyCloakName;
    }

    set keyCloakName(keyCloakName: string) {
        this._keyCloakName = keyCloakName;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get formState(): FormState {
        return this._formState;
    }

    set formState(value: FormState) {
        this._formState = value;
    }

    get adminView(): boolean {
        return this._adminView;
    }

    set adminView(value: boolean) {
        this._adminView = value;
    }

    get newestVersion(): boolean {
        return this._newestVersion;
    }

    set newestVersion(value: boolean) {
        this._newestVersion = value;
    }

    foreignLanguageObjectToMap(mapObject: Map<any, any>): Map<string, string> {
        const map = new Map<string, string>();

        for (const value in mapObject) {
            if (mapObject.hasOwnProperty(value)) {
                if (value !== undefined) {
                    switch (mapObject[value]) {
                        case 'BASIC_KNOWLEDGE':
                            map.set(value, 'Grundkenntnisse');
                            break;
                        case 'GOOD_KNOWLEDGE':
                            map.set(value, 'gute Kenntnisse');
                            break;
                        case 'FLUENTLY':
                            map.set(value, 'fließend');
                            break;
                        case 'NEGOTIABLE':
                            map.set(value, 'verhandlungssicher');
                            break;
                    }
                }
            }
        }

        return map;
    }

    foreignLanguageMapToObject(map: Map<string, string>): any {
        const mapObject = {};

        if (map.size > 0) {
            map.forEach((val, key) => {
                switch (val) {
                    case 'Grundkenntnisse':
                        mapObject[key] = 'BASIC_KNOWLEDGE';
                        break;
                    case 'gute Kenntnisse':
                        mapObject[key] = 'GOOD_KNOWLEDGE';
                        break;
                    case 'fließend':
                        mapObject[key] = 'FLUENTLY';
                        break;
                    case 'verhandlungssicher':
                        mapObject[key] = 'NEGOTIABLE';
                }
            });
        }

        return mapObject;
    }

    addSkillsToKnowledge(userProfile: UserProfile): void {
        for (const skills in this.knowledgeSkills) {
            if (this.knowledgeSkills.hasOwnProperty(skills)) {
                this.knowledgeSkills[skills] = [];
            }
        }
        userProfile.userProjects.forEach((project) => {
            this.skillList.forEach((skills) => {
                project[skills].forEach((skill) => {
                    if (!this.knowledgeSkills[skills].includes(skill) && !userProfile[skills].includes(skill)) {
                        this.knowledgeSkills[skills].push(skill);
                    }
                });
            });
        });
    }

    getUserProfileByKeycloakId(): Observable<UserProfile> {
        return this.httpClient.get<UserProfile>(environment.api + '/user-profile', {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    getAllUserProfiles(): Observable<UserProfile[]> {
        return this.httpClient.get<UserProfile[]>(environment.api + '/user-profile/all', {
            headers: this.HEADER
        });
    }

    saveUserProfile(profileToBeSaved: UserProfile): Observable<string> {
        profileToBeSaved.foreignLanguage = Object.assign({}, this.foreignLanguageMapToObject(profileToBeSaved.foreignLanguage));

        return this.httpClient.post(environment.api + '/user-profile', profileToBeSaved, {
            headers: this.HEADER,
            responseType: 'text'
        });
    }

    releaseUserProfile(userProfile): Observable<string> {
        userProfile.foreignLanguage = Object.assign({}, this.foreignLanguageMapToObject(userProfile.foreignLanguage));

        return this.httpClient.post(environment.api + '/user-profile/release', userProfile, {
            headers: this.HEADER,
            responseType: 'text'
        });
    }

    profileUpdateNotification(userId: string): Observable<any> {
        return this.httpClient.post(environment.api + '/user-profile/send-notification/' + userId, {
            headers: this.HEADER,
            responseType: 'text'
        });
    }

    exportProfile(profile: UserProfile, expandDetails: boolean): Observable<Blob> {
        return this.httpClient.post(environment.api + '/user-profile/get-file?expand=' + expandDetails, profile, {
            headers: this.HEADER,
            responseType: 'blob'
        });
    }

    setProfileVersionArchiver(userID: string, version: number): Observable<any> {
        return this.httpClient.post(environment.api + '/user-profile/archive/' + userID + '/' + version, null, {
            headers: this.HEADER,
            responseType: 'text'
        });
    }

    deleteProfile(profileToBeDeleted: string): Observable<any> {
        return this.httpClient.delete(environment.api + '/user-profile/' + profileToBeDeleted, {
            headers: this.HEADER
        });
    }

    getAllKnowledge(skill: string): Observable<string[]> {
        return this.httpClient.get<string[]>(environment.api + '/user-profile-suggestion/all/' + skill, {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    getProfileVersion(version: number): Observable<UserProfile> {
        return this.httpClient.get<UserProfile>(environment.api + '/user-profile/version/' + version, {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    getProfileVersionAdminView(id: string, version: number): Observable<UserProfile> {
        return this.httpClient.get<UserProfile>(environment.api + '/user-profile/version/' + id + '/' + version, {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    isSameArray(arr1: any[], arr2: any[], isObject?: boolean): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
        let returnValue = true;

        arr1.forEach((value, index: number) => {
            if (!isObject) {
                if (arr1[index] !== arr2[index]) {
                    returnValue = false;
                }
            } else {
                if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index])) {
                    returnValue = false;
                }
            }
        });

        return returnValue;
    }

    isSameMap(map1: Map<string, string>, map2: Map<string, string>): boolean {
        if (map1.size !== map2.size) {
            return false;
        }

        for (const [key] of map1) {
            if (map2.get(key) !== map1.get(key) || !map2.has(key)) {
                return false;
            }
        }

        return true;
    }

    getAllVersionInfosFromUser(): Observable<VersionInfo[]> {
        return this.httpClient.get<VersionInfo[]>(environment.api + '/user-profile/version/all', {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    getAllVersionInfosById(id: string): Observable<VersionInfo[]> {
        return this.httpClient.get<VersionInfo[]>(environment.api + '/user-profile/version/all/' + id, {
            headers: this.HEADER,
            responseType: 'json'
        });
    }

    // tslint:disable-next-line:ban-types
    getUserImageById(id: String): Observable<Blob> {
        return this.httpClient.get<Blob>(environment.employeeApi + '/avatar/' + id, {
            headers: this.HEADER,
            responseType: 'blob' as 'json'
        });
    }

    getVersionComparison(request: ComparisonRequest): Observable<ComparisonResponse> {
        return this.httpClient.get<ComparisonResponse>(
            environment.api + '/compare/' + request.previousVersion + '/' + request.afterVersion,
            {
                headers: this.HEADER,
                responseType: 'json'
            }
        );
    }

    getVersionComparisonAdmin(request: ComparisonRequest, keyCloakId: string): Observable<ComparisonResponse> {
        return this.httpClient.get<ComparisonResponse>(
            environment.api + '/compare/' + keyCloakId + '/' + request.previousVersion + '/' + request.afterVersion,
            {
                headers: this.HEADER,
                responseType: 'json'
            }
        );
    }
}
