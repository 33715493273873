<div class="profiles-overview">
  <h1 class="content-title">Profilübersicht</h1>
  <p>Wählen sie das Profil, das Sie betrachten und bearbeiten wollen</p>
  <mat-expansion-panel class="filter-menu-expansion-panel">
    <mat-expansion-panel-header>
      Suche
    </mat-expansion-panel-header>
    <div class="search-bar-container">
      <h3 class="search-option-title">Nach Namen suchen</h3>
      <input type="text" class="search-bar" placeholder="Profile durchsuchen..." value="" id="by-name" [formControl]="userSearchCriteria" (keyup)="filterProfiles()">
    </div>
    <form class="overview-technologies">
      <h3 class="search-option-title">Nach Skill suchen</h3>
      <mat-form-field class="example-full-width">
        <mat-label>Skill</mat-label>
        <input type="text"
               placeholder="Skill suchen..."
               aria-label="Skill"
               matInput
               [formControl]="selectedSkill"
               [matAutocomplete]="auto"
               (keyup)="filterProfiles()">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredSkill | async" [value]="option" (click)="filterProfiles()">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <div class="expire-date-filter">
      <h3 class="search-option-title">Nach Verfügbarkeit suchen</h3>
      <mat-form-field appearance="fill">
        <mat-label>Verfügbar ab...</mat-label>
        <input matInput [matDatepicker]="projectExpireDatePicker"
               [formControl]="filteredDate"
               (dateChange)="filterProfiles()">
        <mat-datepicker-toggle matSuffix [for]="projectExpireDatePicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="resetDateFilter()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #projectExpireDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
  <div class="feed-container">
    <div class="user-selection-display-item" *ngFor="let user of filteredProfiles"  [ngStyle]="setAvailabilityColor(user)"
         matTooltip="Profil vom {{user.firstname}} {{user.lastname}} anzeigen und bearbeiten"
         (click)="setCurrentlyViewedUser(user)" [routerLink]="['/home/admin', {user: user.keycloakId}]">
      <table class="profile-card">
        <tr>
          <td class="name-info">{{user.firstname}} {{user.lastname}}</td>
          <td class="user-knowHowLevel-lg">{{user.knowHowLevel}}</td>
          <td class="availability-text">{{setAvailabilityText(user)}}</td>
          <td class="availability">
            {{user.projectExpireDate | date : 'dd.MM.yyyy'}}
          </td>
          <td class="user-knowHowLevel-sm">{{user.knowHowLevel}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
