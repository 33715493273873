import { Component, EventEmitter, Input, Output } from '@angular/core';

import { KeycloakDataService } from '../../service/keycloak-data.service';
import { UserDataService } from '../../service/user-data.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
    @Output() toggleDrawer = new EventEmitter<any>();
    @Output() adminViewEnabled = new EventEmitter<boolean>();
    @Input() isAdmin;
    @Input() adminOnly;
    adminView = this.userDataService.adminView;

    constructor(private userData: KeycloakDataService, private userDataService: UserDataService) {}

    logout(): void {
        this.userData.logout();
    }

    toggleAdminView(adminView: boolean): void {
        this.userDataService.adminView = adminView;
        this.adminView = adminView;
    }

    triggerNav(): void {
        this.toggleDrawer.emit();
    }
}
