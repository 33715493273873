import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UserProfile } from '../../../../models/UserProfile';
import { UserDataService } from '../../../../service/user-data.service';

@Component({
    selector: 'app-personal-data-edit',
    templateUrl: './personal-data-edit.component.html',
    styleUrls: ['./personal-data-edit.component.scss']
})
export class PersonalDataEditComponent implements OnInit {
    @Input() userProfile: UserProfile;
    @Input() personalDataFormGroup: FormGroup;
    get adminView(): boolean {
        return this.userDataService.adminView;
    }

    constructor(private userDataService: UserDataService) {}

    ngOnInit(): void {
        this.personalDataFormGroup.markAllAsTouched();
    }
}
