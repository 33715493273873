<table>
  <tr>
    <td class="project-attribute-title">Projektname</td>
    <td>
      <app-input-field [inputFormControl]="project.get('projectName')" [placeholder]="'Projektnamen eingeben'"></app-input-field>
    </td>
  </tr><tr>
  <td class="project-attribute-title">Klient</td>
  <td>
    <app-input-field [inputFormControl]="project.get('client')" [placeholder]="'Klient eingeben'"></app-input-field>
  </td>
</tr><tr>
  <td class="project-attribute-title">Branche</td>
  <td>
    <app-chip-list [isArray]="true" [formController]="project.get('branches')"></app-chip-list>
  </td>
</tr><tr>
  <td class="project-attribute-title">Rolle im Projekt</td>
  <td>
    <app-input-field [inputFormControl]="project.get('role')" [placeholder]="'Rolle im Projekt eingeben'"></app-input-field>
  </td>
</tr><tr>
  <td class="project-attribute-title">Schlüsselkenntnisse</td>
  <td>
    <app-chip-list [isArray]="true" [formController]="project.get('keySkills')"></app-chip-list>
  </td>
</tr><tr>
  <td class="project-attribute-title">Kurzbeschreibung</td>
  <td>
    <app-input-field [inputFormControl]="project.get('projectDescription')" [placeholder]="'Kurzbeschreibung eingeben'" [isTextField]="true"></app-input-field>
  </td>
</tr><tr>
  <td class="project-attribute-title">Aufgaben</td>
  <td>
    <app-chip-list [isArray]="true" [formController]="project.get('todos')" ></app-chip-list>
  </td>
</tr>
  <tr>
    <td class="project-attribute-title">Programmiersprachen</td>
    <td>
      <app-chip-list [isArray]="true" [formController]="project.get('programmingLanguages')" [hasAutocomplete]="true" [autoCompleteName]="'programming-languages'"></app-chip-list>
    </td>
  </tr>
  <tr>
    <td class="project-attribute-title">Technologien / Frameworks</td>
    <td>
      <app-chip-list [isArray]="true" [formController]="project.get('frameworks')" [hasAutocomplete]="true" [autoCompleteName]="'frameworks'"></app-chip-list>
    </td>
  </tr>
  <tr>
    <td class="project-attribute-title">Tools / Entwicklungsumgebungen</td>
    <td>
      <app-chip-list [isArray]="true" [formController]="project.get('tools')" [hasAutocomplete]="true" [autoCompleteName]="'tools'"></app-chip-list>
    </td>
  </tr>
  <tr>
    <td class="project-attribute-title">Datenbanken / Datenbanktools</td>
    <td>
      <app-chip-list [isArray]="true" [formController]="project.get('databases')" [hasAutocomplete]="true" [autoCompleteName]="'databases'"></app-chip-list>
    </td>
  </tr>
  <tr>
    <td class="project-attribute-title">Betriebssysteme</td>
    <td>
      <app-chip-list [isArray]="true" [formController]="project.get('operatingSystems')" [hasAutocomplete]="true" [autoCompleteName]="'operating-systems'"></app-chip-list>
    </td>
  </tr>
  <tr>
    <td class="project-attribute-title">Projektdauer</td>
    <td>
      <app-input-field [inputFormControl]="project.get('projectLength')" [placeholder]="'Projektdauer eingeben (Format: MM/YYYY - MM/YYYY)'"></app-input-field>
    </td>
  </tr><tr>
  <td class="project-attribute-title">Teamgröße</td>
  <td>
    <app-input-field [inputFormControl]="project.get('teamSize')" [placeholder]="'Teamgröße eingeben'"></app-input-field>
  </td>
</tr>
</table>
