<h1 class="section-title">
  Projekte
</h1>
<div>
<input type="text" class="search-bar" placeholder="Projekte nach Namen durchsuchen..." [formControl]="projectEditor.projectSearchCriteria">
<button class="edit-profile-button button-standart-positive" matTooltip="Projekt hinzufügen"
        (click)="openProjectCreateForm(); formState = formStateEnum.ProjectEditFormIsOpen" *ngIf="!(formState===formStateEnum.ProjectEditFormIsOpen)">
  <mat-icon>add</mat-icon>
</button>
</div>
<p *ngIf="projects.length <= 0">Es wurden noch keine Projekte zu diesem Profil hinzugefügt</p>
<app-projects-create *ngIf="projectCreateIndex && formState===formStateEnum.ProjectEditFormIsOpen" [projectFormGroup]="projectFormGroup" [knowledgeForm]="knowledgeForm" (createProjectEmitter)="createProjectEvent($event)"></app-projects-create>
<app-projects-view [projects]="getFilteredProjects()" [projectFormGroup]="projectFormGroup" [projectEditIndex]="projectEditIndex" (editProjectEmitter)="editProject($event)" (projectEditedEmitter)="editProjectEvent($event)" (projectDeleteEmitter)="deleteProject($event)" cdkDropList (cdkDropListDropped)="dropProject($event)"></app-projects-view>
