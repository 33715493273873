<h1 class="section-title">Daten zur Person</h1>
<table class="person-data-table">
  <tr>
    <td class="responsive-react-title">Vorname</td>
    <app-input-field [inputFormControl]="personalDataFormGroup.get('firstname')" [isReadOnly]="true"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Name</td>
    <app-input-field [inputFormControl]="personalDataFormGroup.get('lastname')" [isReadOnly]="true"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Geburtsdatum</td>
    <mat-form-field appearance="fill">
      <input matInput [matDatepicker]="birthdaypicker" [formControl]="personalDataFormGroup.get('birthdate')" readonly>
      <mat-datepicker-toggle disabled matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
      <mat-datepicker #birthdaypicker></mat-datepicker>
    </mat-form-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Abschluss</td>
    <app-input-field [inputFormControl]="personalDataFormGroup.get('education')"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Know-How Stufe</td>
    <app-input-field [inputFormControl]="personalDataFormGroup.get('knowHowLevel')"></app-input-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Muttersprache</td>
    <app-chip-list [formController]="personalDataFormGroup.get('language')"
                   [isArray]="true">
    </app-chip-list>
  </tr>
  <tr>
    <td class="responsive-react-title">Fremdsprachen</td>
    <app-chip-list [formController]="personalDataFormGroup.get('foreignLanguage')" [isMap]="true"></app-chip-list>
  </tr>
  <tr>
    <td class="responsive-react-title">Berufserfahrung</td>
    <mat-form-field appearance="fill">
      <mat-label>Erfahrung seit...</mat-label>
      <input matInput [matDatepicker]="experiencePicker" [formControl]="personalDataFormGroup.get('experience')">
      <mat-datepicker-toggle matSuffix [for]="experiencePicker"></mat-datepicker-toggle>
      <mat-datepicker #experiencePicker></mat-datepicker>
    </mat-form-field>
  </tr>
  <tr>
    <td class="responsive-react-title">Weiterbildung</td>
    <app-chip-list [formController]="personalDataFormGroup.get('certifications')"
                   [isArray]="true"></app-chip-list>
  </tr>
  <tr *ngIf="adminView">
    <td class="responsive-react-title">Projektlaufzeit</td>
    <mat-form-field appearance="fill">
      <mat-label>Projektlaufzeit bis...</mat-label>
      <input matInput [matDatepicker]="projectExpireDatePicker" [formControl]="personalDataFormGroup.get('projectExpireDate')">
      <mat-datepicker-toggle matSuffix [for]="projectExpireDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #projectExpireDatePicker></mat-datepicker>
    </mat-form-field>
  </tr>
</table>
