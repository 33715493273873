import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-projects-edit',
    templateUrl: './projects-edit.component.html',
    styleUrls: ['./projects-edit.component.scss']
})
export class ProjectsEditComponent {
    @Output() editProjectEmitter = new EventEmitter<FormGroup>();
    @Input() projectFormGroup: FormGroup;

    constructor() {}
}
